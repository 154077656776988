import { toString, Union, Record } from "../../app/client/src/.fable/fable-library.3.1.16/Types.js";
import { lambda_type, unit_type, union_type, float64_type, array_type, class_type, option_type, enum_type, int32_type, record_type, string_type } from "../../app/client/src/.fable/fable-library.3.1.16/Reflection.js";
import detect$002Dprovider from "@metamask/detect-provider";
import { InjectiveEnvironment$reflection, FPDECIMAL_ONE, convertToAPRFromRateDecimal } from "../Domain/Types/Common.fs.js";
import { InjectiveProduct__GetKind, InjectiveProduct__GetStrikeWithSpot_32C73145 } from "../Domain/Types/InjectiveProduct.fs.js";
import { pow, fromParts, toNumber as toNumber_1, op_Multiply, op_Division } from "../../app/client/src/.fable/fable-library.3.1.16/Decimal.js";
import Decimal from "../../app/client/src/.fable/fable-library.3.1.16/Decimal.js";
import { op_Addition, fromInt, op_Multiply as op_Multiply_1, equals, op_Division as op_Division_1, compare, fromNumber, fromInteger, toNumber } from "../../app/client/src/.fable/fable-library.3.1.16/Long.js";
import { parse, toDecimal } from "../../app/client/src/.fable/fable-library.3.1.16/BigInt.js";
import { blackScholes } from "black-scholes";
import { value as value_6, map as map_1, some } from "../../app/client/src/.fable/fable-library.3.1.16/Option.js";
import { MsgBroadcaster, WalletStrategy } from "@injectivelabs/wallet-ts";
import { getInjectiveAddress, getEthereumAddress, ChainRestTendermintApi, ChainRestAuthApi, IndexerGrpcOracleApi, ChainGrpcWasmApi, ChainGrpcBankApi } from "@injectivelabs/sdk-ts";
import { MsgExecuteContractCompat, MsgSend } from "@injectivelabs/sdk-ts/dist/cjs/core";
import { BigNumberInBase } from "@injectivelabs/utils";
import { singleton } from "../../app/client/src/.fable/fable-library.3.1.16/AsyncBuilder.js";
import { awaitPromise } from "../../app/client/src/.fable/fable-library.3.1.16/Async.js";
import { FSharpResult$2 } from "../../app/client/src/.fable/fable-library.3.1.16/Choice.js";
import { tryFind, concat, map } from "../../app/client/src/.fable/fable-library.3.1.16/Array.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../app/client/src/.fable/Fable.Promise.2.2.2/Promise.fs.js";
import { promise } from "../../app/client/src/.fable/Fable.Promise.2.2.2/PromiseImpl.fs.js";
import { toArray } from "../../app/client/src/.fable/fable-library.3.1.16/Seq.js";
import { rangeUInt64 } from "../../app/client/src/.fable/fable-library.3.1.16/Range.js";
import { toBase64String } from "../../app/client/src/.fable/fable-library.3.1.16/String.js";
import { get_UTF8 } from "../../app/client/src/.fable/fable-library.3.1.16/Encoding.js";
import { deserializeEscrow } from "../Domain/Types/InjectiveEscrow.fs.js";
import { getNetworkEndpoints } from "@injectivelabs/networks";
import { ProviderContext__enqueueSnackbar_33B60434, EnqueueSnackbarOption, SnackbarProp_Variant_Z609E1E86, ProviderContext__enqueueSnackbar_Z1776A768, ProviderContext$reflection } from "../Notistack/Notistack.fs.js";
import { React_contextProvider_34D9BBBD, useFeliz_React__React_useState_Static_1505, useReact_useContext_37FA55CF, React_createContext_1AE444D8 } from "../../app/client/src/.fable/Feliz.1.62.0/React.fs.js";
import { createElement } from "react";
import * as react from "react";
import { ofArray } from "../../app/client/src/.fable/fable-library.3.1.16/List.js";
import { Interop_reactApi } from "../../app/client/src/.fable/Feliz.1.62.0/Interop.fs.js";

export class Tx extends Record {
    constructor(data, address) {
        super();
        this.data = data;
        this.address = address;
    }
}

export function Tx$reflection() {
    return record_type("Injective.Tx", [], Tx, () => [["data", string_type], ["address", string_type]]);
}

export class WalletStrategyEthereumOptionsArgs extends Record {
    constructor(ethereumChainId, rpcUrl) {
        super();
        this.ethereumChainId = (ethereumChainId | 0);
        this.rpcUrl = rpcUrl;
    }
}

export function WalletStrategyEthereumOptionsArgs$reflection() {
    return record_type("Injective.WalletStrategyEthereumOptionsArgs", [], WalletStrategyEthereumOptionsArgs, () => [["ethereumChainId", enum_type("Injective.EthereumChainId", int32_type, [["Mainnet", 1], ["Ropsten", 3], ["Rinkeby", 4], ["Goerli", 5], ["Kovan", 42], ["Injective", 888], ["Ganache", 1337], ["HardHat", 31337]])], ["rpcUrl", option_type(string_type)]]);
}

export class WalletStrategyArgs extends Record {
    constructor(chainId, ethereumOptions, disabledWallet, wallet) {
        super();
        this.chainId = chainId;
        this.ethereumOptions = ethereumOptions;
        this.disabledWallet = disabledWallet;
        this.wallet = wallet;
    }
}

export function WalletStrategyArgs$reflection() {
    return record_type("Injective.WalletStrategyArgs", [], WalletStrategyArgs, () => [["chainId", string_type], ["ethereumOptions", option_type(class_type("Injective.WalletStrategyEthereumOptions"))], ["disabledWallet", array_type(string_type)], ["wallet", option_type(string_type)]]);
}

export class SignTxOption extends Record {
    constructor(address, chainId) {
        super();
        this.address = address;
        this.chainId = chainId;
    }
}

export function SignTxOption$reflection() {
    return record_type("Injective.SignTxOption", [], SignTxOption, () => [["address", string_type], ["chainId", string_type]]);
}

export class SendTxParams extends Record {
    constructor(tx, options) {
        super();
        this.tx = tx;
        this.options = options;
    }
}

export function SendTxParams$reflection() {
    return record_type("Injective.SendTxParams", [], SendTxParams, () => [["tx", class_type("Injective.DirectSignResponse")], ["options", SignTxOption$reflection()]]);
}

export class FetchBalanceArgs extends Record {
    constructor(accountAddress, denom) {
        super();
        this.accountAddress = accountAddress;
        this.denom = denom;
    }
}

export function FetchBalanceArgs$reflection() {
    return record_type("Injective.FetchBalanceArgs", [], FetchBalanceArgs, () => [["accountAddress", string_type], ["denom", string_type]]);
}

export class MsgBroadcasterOptionsInput extends Record {
    constructor(network, walletStrategy, networkEndpoints, txTimeout) {
        super();
        this.network = network;
        this.walletStrategy = walletStrategy;
        this.networkEndpoints = networkEndpoints;
        this.txTimeout = txTimeout;
    }
}

export function MsgBroadcasterOptionsInput$reflection() {
    return record_type("Injective.MsgBroadcasterOptionsInput", [], MsgBroadcasterOptionsInput, () => [["network", string_type], ["walletStrategy", class_type("Injective.WalletStrategy")], ["networkEndpoints", class_type("Injective.NetworkEndpoints")], ["txTimeout", float64_type]]);
}

export class MsgType extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Send", "ExecuteContractCompat"];
    }
}

export function MsgType$reflection() {
    return union_type("Injective.MsgType", [], MsgType, () => [[["Item", class_type("Injective.MsgSend")]], [["Item", class_type("Injective.MsgExecuteContractCompat")]]]);
}

export class MsgBroadcasterTxOptionsInput extends Record {
    constructor(msgs, address) {
        super();
        this.msgs = msgs;
        this.address = address;
    }
}

export function MsgBroadcasterTxOptionsInput$reflection() {
    return record_type("Injective.MsgBroadcasterTxOptionsInput", [], MsgBroadcasterTxOptionsInput, () => [["msgs", class_type("Injective.Msgs")], ["address", string_type]]);
}

export class Eip712ConvertTxArgsInput extends Record {
    constructor(accountNumber, sequence, timeoutHeight, chainId) {
        super();
        this.accountNumber = accountNumber;
        this.sequence = sequence;
        this.timeoutHeight = timeoutHeight;
        this.chainId = chainId;
    }
}

export function Eip712ConvertTxArgsInput$reflection() {
    return record_type("Injective.Eip712ConvertTxArgsInput", [], Eip712ConvertTxArgsInput, () => [["accountNumber", string_type], ["sequence", string_type], ["timeoutHeight", string_type], ["chainId", string_type]]);
}

export class GetEip712TypedDataInput extends Record {
    constructor(msgs, tx, ethereumChainId) {
        super();
        this.msgs = msgs;
        this.tx = tx;
        this.ethereumChainId = (ethereumChainId | 0);
    }
}

export function GetEip712TypedDataInput$reflection() {
    return record_type("Injective.GetEip712TypedDataInput", [], GetEip712TypedDataInput, () => [["msgs", class_type("Injective.Msgs")], ["tx", class_type("Injective.Eip712ConvertTxArgs")], ["ethereumChainId", enum_type("Injective.EthereumChainId", int32_type, [["Mainnet", 1], ["Ropsten", 3], ["Rinkeby", 4], ["Goerli", 5], ["Kovan", 42], ["Injective", 888], ["Ganache", 1337], ["HardHat", 31337]])]]);
}

export class MetaMaskEthereumProviderRequestArgs extends Record {
    constructor(method, params$0027) {
        super();
        this.method = method;
        this["params\u0027"] = params$0027;
    }
}

export function MetaMaskEthereumProviderRequestArgs$reflection() {
    return record_type("Injective.MetaMaskEthereumProviderRequestArgs", [], MetaMaskEthereumProviderRequestArgs, () => [["method", string_type], ["params\u0027", array_type(string_type)]]);
}

export const detectEthereumProvider = detect$002Dprovider;

export function estimatedInjectiveHoldingYield(product, price, spot, strikeOption) {
    let strike, callput, matchValue, ttm, k_1, volatility;
    const maturity = product.Duration;
    return convertToAPRFromRateDecimal(maturity, (price.tag === 1) ? (strike = ((strikeOption == null) ? InjectiveProduct__GetStrikeWithSpot_32C73145(product, spot) : strikeOption), (callput = (matchValue = InjectiveProduct__GetKind(product), (matchValue.tag === 1) ? "put" : "call"), (ttm = op_Division(new Decimal(toNumber(maturity)), new Decimal(31557600)), (k_1 = op_Division(strike, spot), (volatility = op_Division(price.fields[0], toDecimal(FPDECIMAL_ONE)), op_Multiply(new Decimal(toNumber_1(blackScholes(fromParts(1, 0, 0, false, 0), k_1, ttm, volatility, fromParts(0, 0, 0, false, 0), callput))), toDecimal(FPDECIMAL_ONE))))))) : ((price.tag === 2) ? price.fields[0].Price : price.fields[0]));
}

export function estimatedInjectiveHoldingYieldForEscrowVolatilityPrice(product, vol, spot, strike, ttmInSec) {
    let callput;
    const matchValue = InjectiveProduct__GetKind(product);
    callput = ((matchValue.tag === 1) ? "put" : "call");
    const ttm = op_Division(new Decimal(toNumber(ttmInSec)), new Decimal(31557600));
    const k = op_Division(strike, spot);
    return new Decimal(toNumber_1(blackScholes(fromParts(1, 0, 0, false, 0), k, ttm, vol, fromParts(0, 0, 0, false, 0), callput)));
}

export function getEscrowVolatilityPrice(price, totalDepositedAmount, escrowBalance, nbOption) {
    return op_Division(op_Multiply(op_Multiply(price, toDecimal(totalDepositedAmount)), escrowBalance), nbOption);
}

export function createDefaultWalletArgs(chainId, wallet) {
    return new WalletStrategyArgs(chainId, void 0, new Array(0), wallet);
}

export function createMetaMaskWalletArgs(chainId, ethereumOptions) {
    return new WalletStrategyArgs(chainId, ethereumOptions, new Array(0), "metamask");
}

export function msgBroadcasterOptions(input) {
    return {
        network: input.network,
        walletStrategy: input.walletStrategy,
        networkEndpoints: input.networkEndpoints,
        txTimeout: input.txTimeout,
    };
}

export function msgBroadcasterTxOptions(input) {
    return {
        msgs: input.msgs,
        address: input.address,
    };
}

export function coin(denom, amount) {
    return {
        denom: denom,
        amount: amount,
    };
}

export function msgSendParams(input, source, destination) {
    return {
        amount: input,
        srcInjectiveAddress: source,
        dstInjectiveAddress: destination,
    };
}

export function msgExecuteContractCompatParams(funds, sender, contractAddress, msg) {
    return {
        funds: funds,
        sender: sender,
        contractAddress: contractAddress,
        msg: msg,
    };
}

export function msgSendToMsgs(msg) {
    return msg;
}

export function MsgExecuteContractCompatToMsgs(msg) {
    return msg;
}

export function EthereumOptions(ethereumChainId, rpcUrl) {
    try {
        return {
            ethereumChainId: ethereumChainId,
            rpcUrl: rpcUrl,
        };
    }
    catch (ex) {
        console.log(some("failed create ethereum options"));
        throw (new Error(toString(ex)));
    }
}

export function createWalletStrategy(chainId, wallet) {
    try {
        if (wallet == null) {
            return new WalletStrategy(createDefaultWalletArgs(chainId, void 0));
        }
        else if (wallet === "metamask") {
            const ethereumOptions = EthereumOptions(1, "https://eth-mainnet.g.alchemy.com/v2");
            return new WalletStrategy(createMetaMaskWalletArgs(chainId, ethereumOptions));
        }
        else {
            return new WalletStrategy(createDefaultWalletArgs(chainId, wallet));
        }
    }
    catch (ex) {
        console.log(some("failed create wallet strategy"));
        throw (new Error(toString(ex)));
    }
}

export function paginationOption(offset) {
    return {
        offset: offset,
    };
}

export function chainGrpcBankApi(grpc, env) {
    const url = (env.tag === 0) ? grpc : grpc;
    return new ChainGrpcBankApi(url);
}

export function chainGrpcWasmApi(grpc, env) {
    const url = (env.tag === 0) ? grpc : grpc;
    return new ChainGrpcWasmApi(grpc);
}

export function indexerGrpcOracleApi(indexer) {
    return new IndexerGrpcOracleApi(indexer);
}

export function chainRestAuthApi(endpoint) {
    return new ChainRestAuthApi(endpoint);
}

export function chainRestTendermintApi(endpoint) {
    return new ChainRestTendermintApi(endpoint);
}

export function msgBroadcaster(msgBroadcasterOptions_1) {
    return new MsgBroadcaster(msgBroadcasterOptions_1);
}

export function msgSend(msgSendParams_1) {
    return new MsgSend(msgSendParams_1);
}

export function msgExecuteContractCompat(msgExecuteContractCompatParams_1) {
    return new MsgExecuteContractCompat(msgExecuteContractCompatParams_1);
}

export function createBigNumber(number) {
    return new BigNumberInBase(number);
}

export function getStringArrayFromJsonString(s) {
    try {
        return JSON.parse(s);
    }
    catch (ex) {
        console.log(some(ex));
        return [];
    }
}

export function getKeyValuePairsFromJsonString(s) {
    try {
        return JSON.parse(s);
    }
    catch (ex) {
        return [];
    }
}

export function getTypeFromJsonString(s) {
    try {
        return JSON.parse(s);
    }
    catch (ex) {
        console.log(some(ex));
        throw (new Error("Faile in getTypeFromJsonString"));
    }
}

export function checkIsOkxAndGetWalletName(address, wallet) {
    return singleton.Delay(() => {
        const walletName = wallet;
        return singleton.TryWith(singleton.Delay(() => {
            if (walletName === "metamask") {
                const inputInJs = {
                    method: "eth_requestAccounts",
                };
                const okx = window.okxwallet;
                return (!(okx == null)) ? singleton.Bind(awaitPromise(window.okxwallet.request(inputInJs)), (_arg1) => {
                    let userCurrentAddr;
                    if (address.indexOf("inj") === 0) {
                        try {
                            userCurrentAddr = getEthereumAddress(address);
                        }
                        catch (matchValue) {
                            userCurrentAddr = (void 0);
                        }
                    }
                    else {
                        userCurrentAddr = address;
                    }
                    return (userCurrentAddr == null) ? singleton.Return(walletName) : ((_arg1[0] === userCurrentAddr) ? singleton.Return("Okx") : singleton.Return(walletName));
                }) : singleton.Return(walletName);
            }
            else {
                return singleton.Return(walletName);
            }
        }), (_arg2) => singleton.Return(walletName));
    });
}

export function getWalletAddresses(wallet) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(awaitPromise(wallet.getAddresses()), (_arg1) => {
        const addresses = _arg1;
        return (addresses.length === 0) ? singleton.Return(new FSharpResult$2(1, "Failed to get wallet address")) : singleton.Return(new FSharpResult$2(0, map((x) => {
            if (x.indexOf("0x") === 0) {
                return getInjectiveAddress(x);
            }
            else {
                return x;
            }
        }, addresses)));
    })), (_arg2) => {
        const ex = _arg2;
        console.log(some(ex));
        return singleton.Return(new FSharpResult$2(1, toString(ex)));
    }));
}

export function getAddressBalances(api, address, paginationOption_1) {
    return awaitPromise(api.fetchBalances(address, paginationOption_1));
}

export function getAddressBalance(api, address, denom) {
    return awaitPromise(api.fetchBalance(new FetchBalanceArgs(address, denom)));
}

export function getDenomBalance(api, address, denom) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(getAddressBalance(api, address, denom), (_arg1) => singleton.Return(_arg1.amount))), (_arg2) => singleton.Return("0")));
}

export function getAddressBalancesWithPagination(api, address) {
    return singleton.Delay(() => singleton.Bind(getAddressBalances(api, address, paginationOption(fromInteger(0, true, 2))), (_arg1) => {
        let nbFetch, pr;
        const balances = _arg1;
        const total = fromNumber(balances.pagination.total, true);
        const limit = fromInteger(100, true, 2);
        return singleton.Bind((compare(total, limit) > 0) ? (nbFetch = op_Division_1(total, limit), awaitPromise((pr = map((x) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Delay_62FBFDE1(promise, () => {
            if (equals(x, fromInteger(0, true, 2))) {
                return Promise.resolve(balances.balances);
            }
            else {
                const paginationArgs_1 = paginationOption(op_Multiply_1(limit, x));
                return api.fetchBalances(address, paginationArgs_1).then(((_arg2) => (Promise.resolve(_arg2.balances))));
            }
        }).catch(((_arg3) => (Promise.resolve([]))))))), toArray(rangeUInt64(fromInteger(0, true, 2), fromInt(1), nbFetch))), Promise.all(pr)))) : singleton.Delay(() => singleton.Return([balances.balances])), (_arg4) => singleton.Return(concat(_arg4)));
    }));
}

export function getDenomBalanceDashboard(coins, denom) {
    const coinOption = tryFind((x) => (x.denom === denom), coins);
    if (coinOption == null) {
        return "0";
    }
    else {
        return coinOption.amount;
    }
}

export function getAddressTokenBalanceUi(api, address, denom, tokenDecimal) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(getAddressBalance(api, address, denom), (_arg1) => singleton.Combine(((tokenDecimal > 18) ? true : (tokenDecimal < 0)) ? ((() => {
        throw (new Error("Invalid token decimals"));
    })(), singleton.Zero()) : singleton.Zero(), singleton.Delay(() => {
        const tokenMultiplier = pow(fromParts(10, 0, 0, false, 0), tokenDecimal);
        return singleton.Return(op_Division(toDecimal(parse(_arg1.amount)), tokenMultiplier));
    })))), (_arg2) => {
        console.log(some(_arg2));
        return singleton.Return(fromParts(0, 0, 0, false, 0));
    }));
}

export function getWasmSmartQuery(api, contract, query) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(awaitPromise(api.fetchSmartContractState(contract, map_1((q) => toBase64String(get_UTF8().getBytes(q)), query))), (_arg1) => singleton.Return(get_UTF8().getString(_arg1.data)))), (_arg2) => {
        console.log(some(_arg2));
        return singleton.Return("");
    }));
}

export function getEscrowArrFromStringArr(api, contracts, query) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
        let pr_1;
        const data = map_1((q) => toBase64String(get_UTF8().getBytes(q)), query);
        return singleton.Bind(awaitPromise((pr_1 = map((addr) => {
            const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Delay_62FBFDE1(promise, () => (api.fetchSmartContractState(addr, data).then(((_arg1) => (Promise.resolve(_arg1)))))).catch(((_arg2) => (Promise.resolve((void 0))))))));
            return pr.then(((op) => {
                if (op == null) {
                    return void 0;
                }
                else {
                    const r_1 = op;
                    return deserializeEscrow(addr, get_UTF8().getString(r_1.data));
                }
            }));
        }, contracts), Promise.all(pr_1))), (_arg3) => singleton.Return(_arg3));
    }), (_arg4) => singleton.Return([])));
}

export function getWasmContractsListQuery(api, codeId) {
    return singleton.Delay(() => {
        const maxPage = fromInteger(100, true, 2);
        let cont = true;
        let nb = fromInteger(1, true, 2);
        return singleton.Bind(awaitPromise(api.fetchContractCodeContracts(codeId, void 0)), (_arg1) => {
            const res = _arg1;
            let paginationRes = res.pagination.total;
            let arr = res.contractsList;
            return singleton.Combine(singleton.While(() => cont, singleton.Delay(() => {
                if (res.pagination.total === toNumber(maxPage)) {
                    const pag = paginationOption(op_Multiply_1(maxPage, nb));
                    void awaitPromise(new Promise(resolve => setTimeout(resolve, 1000)));
                    return singleton.Bind(awaitPromise(api.fetchContractCodeContracts(codeId, pag)), (_arg2) => {
                        const resTemp = _arg2;
                        arr = concat([arr, resTemp.contractsList]);
                        nb = op_Addition(nb, fromInteger(1, true, 2));
                        if (resTemp.pagination.total < toNumber(maxPage)) {
                            cont = false;
                            return singleton.Zero();
                        }
                        else {
                            return singleton.Zero();
                        }
                    });
                }
                else {
                    cont = false;
                    return singleton.Zero();
                }
            })), singleton.Delay(() => singleton.Return(arr)));
        });
    });
}

export function getOracleList(api) {
    return singleton.Delay(() => singleton.Bind(awaitPromise(api.fetchOracleList()), (_arg1) => singleton.Return(_arg1)));
}

export function createMsgBroadcastClient(network, walletStrategy) {
    return msgBroadcaster(msgBroadcasterOptions(new MsgBroadcasterOptionsInput(network, walletStrategy, getNetworkEndpoints(network), 400)));
}

export function createBroadcast(msgBroadcaster_1, msgType, sender) {
    return singleton.Delay(() => singleton.Bind(awaitPromise(msgBroadcaster_1.broadcast(msgBroadcasterTxOptions(new MsgBroadcasterTxOptionsInput((msgType.tag === 1) ? MsgExecuteContractCompatToMsgs(msgType.fields[0]) : msgSendToMsgs(msgType.fields[0]), sender)))), (_arg1) => singleton.Return(_arg1)));
}

export function getCoinAmountFromFloat(amount, decimals) {
    if ((decimals > 18) ? true : (decimals < 0)) {
        throw (new Error("Invalid token decimals"));
    }
    return createBigNumber((amount / Math.pow(10, 18 - decimals)).toString()).toWei().toFixed();
}

export function getAccountDetails(chainRestAuthApi_1, address) {
    return awaitPromise(chainRestAuthApi_1.fetchAccount(address));
}

export function getLatestBlock(chainRestTendermintApi_1) {
    return awaitPromise(chainRestTendermintApi_1.fetchLatestBlock());
}

export function eip712ConvertTxArgs(input) {
    return {
        accountNumber: input.accountNumber,
        sequence: input.sequence,
        timeoutHeight: input.timeoutHeight,
        chainId: input.chainId,
    };
}

export function getEthereumProvider() {
    return awaitPromise(detectEthereumProvider());
}

export function requestMetamask(provider, input) {
    return awaitPromise(provider.request({
        method: input.method,
        params: input["params\u0027"],
    }));
}

export function createBufferFromByteArray(arr) {
    return arr;
}

export function signEip712Tx(walletStrategy, data, address) {
    return awaitPromise(walletStrategy.signEip712TypedData(data, address));
}

export class StoreState extends Record {
    constructor(injectiveAddress, etheriumAddress, walletStrategy, connectWallet, disconnectWallet) {
        super();
        this.injectiveAddress = injectiveAddress;
        this.etheriumAddress = etheriumAddress;
        this.walletStrategy = walletStrategy;
        this.connectWallet = connectWallet;
        this.disconnectWallet = disconnectWallet;
    }
}

export function StoreState$reflection() {
    return record_type("Injective.StoreState", [], StoreState, () => [["injectiveAddress", string_type], ["etheriumAddress", string_type], ["walletStrategy", option_type(class_type("Injective.WalletStrategy"))], ["connectWallet", lambda_type(option_type(string_type), lambda_type(option_type(ProviderContext$reflection()), lambda_type(InjectiveEnvironment$reflection(), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [unit_type]))))], ["disconnectWallet", lambda_type(unit_type, unit_type)]]);
}

export function connectWalletDefaultFn(walletType, snackbarOption, environment) {
    return singleton.Delay(() => {
        console.log(some("Error. Wallet not connected"));
        return singleton.Zero();
    });
}

export function disconnectWalletDefaultFn() {
}

export const walletContext = React_createContext_1AE444D8(void 0, new StoreState("", "", void 0, (walletType, snackbarOption, environment) => connectWalletDefaultFn(walletType, snackbarOption, environment), () => {
    disconnectWalletDefaultFn();
}));

export function useWalletStore() {
    return useReact_useContext_37FA55CF(walletContext);
}

export function InstallMetamask() {
    const linkHtml = createElement("a", {
        children: "https://metamask.io/download/",
        href: "https://metamask.io/download/",
        target: "_blank",
    });
    const children = ofArray([createElement("span", {
        children: ["Failed to connect. Please first install MetaMask at "],
    }), linkHtml]);
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

export function WalletContextProvider(walletContextProviderInputProps) {
    const children = walletContextProviderInputProps.children;
    const patternInput = useFeliz_React__React_useState_Static_1505("");
    const setEtheriumAddress = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505("");
    const setInjectiveAddress = patternInput_1[1];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(void 0);
    const walletStrategy = patternInput_2[0];
    const setWalletStrategy = patternInput_2[1];
    return React_contextProvider_34D9BBBD(walletContext, new StoreState(patternInput_1[0], patternInput[0], walletStrategy, (walletType, snackbarOption, environment) => singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
        console.log(some("Connecting wallet..."));
        const chainId = (environment.tag === 0) ? "injective-888" : "injective-1";
        const walletStrategy_1 = createWalletStrategy(chainId, walletType);
        return singleton.Combine((walletType == null) ? (void 0, singleton.Zero()) : ((walletType === "metamask") ? singleton.Bind(getEthereumProvider(), (_arg1) => {
            const provider = _arg1;
            const inputInJs = {
                method: "eth_requestAccounts",
            };
            if (provider == null) {
                throw (new Error("No MetaMask Wallet Found"));
                return singleton.Zero();
            }
            else {
                const p = provider;
                switch (chainId) {
                    case "injective-1": {
                        const chainIdParams = {
                            chainId: "0x1",
                        };
                        const args = {
                            method: "wallet_switchEthereumChain",
                            params: [chainIdParams],
                        };
                        void awaitPromise(p.request(args));
                        return singleton.Zero();
                    }
                    case "injective-888": {
                        const chainIdParams_1 = {
                            chainId: "0x5",
                        };
                        const args_1 = {
                            method: "wallet_switchEthereumChain",
                            params: [chainIdParams_1],
                        };
                        void awaitPromise(p.request(args_1));
                        return singleton.Zero();
                    }
                    default: {
                        return singleton.Zero();
                    }
                }
            }
        }) : (void 0, singleton.Zero())), singleton.Delay(() => {
            setWalletStrategy(walletStrategy_1);
            return singleton.Bind(getWalletAddresses(walletStrategy_1), (_arg2) => {
                const addresses = _arg2;
                const walletName = (walletType == null) ? "" : walletType;
                if (addresses.tag === 1) {
                    if (snackbarOption == null) {
                        return singleton.Zero();
                    }
                    else {
                        ProviderContext__enqueueSnackbar_Z1776A768(snackbarOption, "Failed to connect " + walletName, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(0, false));
                        return singleton.Zero();
                    }
                }
                else {
                    const address = addresses.fields[0][0];
                    setEtheriumAddress(getEthereumAddress(address));
                    setInjectiveAddress(address);
                    if (snackbarOption == null) {
                        return singleton.Zero();
                    }
                    else {
                        const snackbar = snackbarOption;
                        if (walletName === "metamask") {
                            ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "Connected to Okx/ " + walletName, SnackbarProp_Variant_Z609E1E86("success"), new EnqueueSnackbarOption(0, false));
                            return singleton.Zero();
                        }
                        else {
                            ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "Connected to " + walletName, SnackbarProp_Variant_Z609E1E86("success"), new EnqueueSnackbarOption(0, false));
                            return singleton.Zero();
                        }
                    }
                }
            });
        }));
    }), (_arg3) => {
        const ex = _arg3;
        if (snackbarOption == null) {
            throw (new Error(toString(ex)));
            return singleton.Zero();
        }
        else {
            const snackbar_2 = snackbarOption;
            if (toString(ex) === "Error: No MetaMask Wallet Found") {
                ProviderContext__enqueueSnackbar_33B60434(snackbar_2, createElement(InstallMetamask, null), SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(0, false));
                return singleton.Zero();
            }
            else {
                ProviderContext__enqueueSnackbar_Z1776A768(snackbar_2, toString(ex), SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(0, false));
                return singleton.Zero();
            }
        }
    })), () => {
        if (walletStrategy == null) {
        }
        else {
            const w_2 = walletStrategy;
            setWalletStrategy(void 0);
            setEtheriumAddress("");
            setInjectiveAddress("");
        }
    }), react.createElement(react.Fragment, {}, ...children));
}

export function copyTextWeb(text) {
    return singleton.Delay(() => {
        void awaitPromise(value_6(navigator.clipboard).writeText(text));
        return singleton.Zero();
    });
}

export function disconnectWallet(walletStrategy) {
    return singleton.Delay(() => {
        if (walletStrategy != null) {
            void awaitPromise(value_6(walletStrategy).disconnectWallet());
            return singleton.Zero();
        }
        else {
            return singleton.Zero();
        }
    });
}

export function getNetworkByInjectiveEnvironment(envvironment) {
    if (envvironment.tag === 0) {
        return "testnetSentry";
    }
    else {
        return "mainnetSentry";
    }
}

export function getWalletTypeByWalletName(walletName) {
    switch (walletName) {
        case "Ninji": {
            return ["ninji", false];
        }
        case "OKX / MetaMask": {
            return ["metamask", true];
        }
        case "Keplr": {
            return ["keplr", false];
        }
        case "Leap": {
            return ["leap", false];
        }
        default: {
            return [void 0, false];
        }
    }
}

