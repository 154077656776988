import { createElement } from "react";
import { join } from "./.fable/fable-library.3.1.16/String.js";
import utils$002Emodule from "./styles/utils.module.scss";
import { Interop_reactApi } from "./.fable/Feliz.1.62.0/Interop.fs.js";

export function ComingSoonMessage() {
    return createElement("div", {
        className: join(" ", ["coming-soon", utils$002Emodule.center]),
        children: Interop_reactApi.Children.toArray([createElement("span", {
            className: join(" ", [utils$002Emodule["boldness-500"]]),
            children: "Coming Soon...",
        })]),
    });
}

