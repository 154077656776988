import { interpolate, toText } from "../.fable/fable-library.3.1.16/String.js";
import { createElement } from "react";
import { createObj } from "../.fable/fable-library.3.1.16/Util.js";
import { empty, singleton, append, delay, toList } from "../.fable/fable-library.3.1.16/Seq.js";
import { value as value_6 } from "../.fable/fable-library.3.1.16/Option.js";

export function Section(sectionInputProps) {
    const id = sectionInputProps.id;
    const children = sectionInputProps.children;
    const classes = toText(interpolate("py-10 md:px-10 flex flex-col gap-20 relative scroll-mt-24", []));
    return createElement("section", createObj(toList(delay(() => append((id != null) ? singleton(["id", value_6(id)]) : empty(), delay(() => append(singleton(["className", classes]), delay(() => singleton(["children", children])))))))));
}

