import { createElement } from "react";
import { equals } from "../.fable/fable-library.3.1.16/Util.js";
import { Interop_reactApi } from "../.fable/Feliz.1.62.0/Interop.fs.js";

export function Modal(modalInputProps) {
    const onClose = modalInputProps.onClose;
    const children = modalInputProps.children;
    const id = "m1";
    return createElement("div", {
        id: id,
        className: "overflow-hidden\n                fixed\n                inset-0\n                z-50\n                flex\n                items-center\n                justify-center\n                size-full\n                bg-slate-400/20\n                backdrop-blur-md",
        children: children,
        onClick: (e) => {
            if (equals(document.getElementById(id), e.target)) {
                onClose();
            }
        },
    });
}

export function OpenBurgerMenu(openBurgerMenuInputProps) {
    const onClose = openBurgerMenuInputProps.onClose;
    const children = openBurgerMenuInputProps.children;
    return createElement("div", {
        id: "mb",
        className: "overflow-hidden\n                fixed\n                inset-0\n                z-50\n                items-center\n                justify-center\n                size-full\n                backdrop-blur-md\n                lg:hidden\n                text-exo-primary\n                flex",
        children: Interop_reactApi.Children.toArray([createElement("ul", {
            className: "gap-20 flex flex-col text-center",
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        })]),
        onClick: (_arg1) => {
            onClose();
        },
    });
}

