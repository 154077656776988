import { Record, Union } from "./.fable/fable-library.3.1.16/Types.js";
import { record_type, array_type, class_type, option_type, union_type } from "./.fable/fable-library.3.1.16/Reflection.js";
import { InjectiveProductKind, InjectiveProductKind__GetExpectation, InjectiveProductKind$reflection } from "../../../lib/Domain/Types/InjectiveProduct.fs.js";
import { MuiHelpers_createElement } from "./.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import MenuItem from "@material-ui/core/MenuItem";
import { Interop_reactApi } from "./.fable/Feliz.1.62.0/Interop.fs.js";
import Typography from "@material-ui/core/Typography";
import { createElement } from "react";
import { join } from "./.fable/fable-library.3.1.16/String.js";
import utils$002Emodule from "./styles/utils.module.scss";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import { Browser_Types_Event__Event_get_Checked, Browser_Types_Event__Event_get_Value } from "./.fable/Fable.React.7.4.0/Fable.React.Extensions.fs.js";
import { concat, contains, tail, map } from "./.fable/fable-library.3.1.16/Array.js";
import { fromInteger, equals } from "./.fable/fable-library.3.1.16/Long.js";
import { useReact_useEffect_Z101E1A95, useFeliz_React__React_useState_Static_1505 } from "./.fable/Feliz.1.62.0/React.fs.js";
import { startImmediate } from "./.fable/fable-library.3.1.16/Async.js";
import { singleton } from "./.fable/fable-library.3.1.16/AsyncBuilder.js";
import { stringHash } from "./.fable/fable-library.3.1.16/Util.js";
import Checkbox from "@material-ui/core/Checkbox";
import { Array_distinct } from "./.fable/fable-library.3.1.16/Seq2.js";
import { append, singleton as singleton_1, delay, toList } from "./.fable/fable-library.3.1.16/Seq.js";
import { CurrencyIcon } from "./Currency.fs.js";

export class EscrowFilterType extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["OptionKind", "DateBefore", "DateAfter", "SpotDenom"];
    }
}

export function EscrowFilterType$reflection() {
    return union_type("Filtering.EscrowFilterType", [], EscrowFilterType, () => [[], [], [], []]);
}

export class EscrowFilters extends Record {
    constructor(OptionKind, DayBefore, DayAfter, SpotDenom) {
        super();
        this.OptionKind = OptionKind;
        this.DayBefore = DayBefore;
        this.DayAfter = DayAfter;
        this.SpotDenom = SpotDenom;
    }
}

export function EscrowFilters$reflection() {
    return record_type("Filtering.EscrowFilters", [], EscrowFilters, () => [["OptionKind", option_type(InjectiveProductKind$reflection())], ["DayBefore", option_type(class_type("System.UInt64"))], ["DayAfter", option_type(class_type("System.UInt64"))], ["SpotDenom", option_type(array_type(class_type("Token.TokenInfo")))]]);
}

export function EscrowOptionKindSelectBox(escrowOptionKindSelectBoxInputProps) {
    const dispatch = escrowOptionKindSelectBoxInputProps.dispatch;
    const filterValue = escrowOptionKindSelectBoxInputProps.filterValue;
    const options = escrowOptionKindSelectBoxInputProps.options;
    const label = escrowOptionKindSelectBoxInputProps.label;
    const SelectItem = (o) => MuiHelpers_createElement(MenuItem, [["key", o], ["value", o], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Typography, [["children", o]])])]]);
    let value_8;
    const matchValue = filterValue.OptionKind;
    value_8 = ((matchValue == null) ? "" : InjectiveProductKind__GetExpectation(matchValue));
    return createElement("div", {
        style: {
            minWidth: 160,
        },
        className: join(" ", [utils$002Emodule["margin-s-right"]]),
        children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(FormControl, [["variant", "outlined"], ["size", "small"], ["fullWidth", true], ["required", false], ["error", false], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(InputLabel, [["children", label]]), MuiHelpers_createElement(Select, [["label", label], ["value", (value_8 === "None") ? "" : value_8], ["onChange", (e, _arg24) => {
            const v = Browser_Types_Event__Event_get_Value(e);
            dispatch(new EscrowFilters((v === "Bullish") ? (new InjectiveProductKind(0)) : ((v === "Bearish") ? (new InjectiveProductKind(1)) : (void 0)), filterValue.DayBefore, filterValue.DayAfter, filterValue.SpotDenom));
        }], ["children", Interop_reactApi.Children.toArray(Array.from(map((o_1) => {
            if (o_1 === "") {
                return SelectItem("None");
            }
            else {
                return SelectItem(o_1);
            }
        }, options)))]])])]])]),
    });
}

export function EscrowExpirySelectBox(escrowExpirySelectBoxInputProps) {
    const dispatch = escrowExpirySelectBoxInputProps.dispatch;
    const filterValue = escrowExpirySelectBoxInputProps.filterValue;
    const options = escrowExpirySelectBoxInputProps.options;
    const label = escrowExpirySelectBoxInputProps.label;
    const SelectItem = (o) => MuiHelpers_createElement(MenuItem, [["key", o], ["value", o], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Typography, [["children", o]])])]]);
    let value_8;
    try {
        const matchValue = [filterValue.DayBefore, filterValue.DayAfter];
        let pattern_matching_result, da, db;
        if (matchValue[0] != null) {
            if (matchValue[1] != null) {
                pattern_matching_result = 0;
                da = matchValue[1];
                db = matchValue[0];
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
        switch (pattern_matching_result) {
            case 0: {
                value_8 = options.find((tupledArg) => {
                    const _arg1 = tupledArg[1];
                    if (equals(db, fromInteger(_arg1[0], true, 2))) {
                        return equals(da, fromInteger(_arg1[1], true, 2));
                    }
                    else {
                        return false;
                    }
                })[0];
                break;
            }
            case 1: {
                value_8 = "";
                break;
            }
        }
    }
    catch (matchValue_1) {
        value_8 = "";
    }
    return createElement("div", {
        style: {
            minWidth: 160,
        },
        className: join(" ", [utils$002Emodule["margin-s-right"]]),
        children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(FormControl, [["variant", "outlined"], ["size", "small"], ["fullWidth", true], ["required", false], ["error", false], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(InputLabel, [["children", label]]), MuiHelpers_createElement(Select, [["label", label], ["value", (value_8 === "None") ? "" : value_8], ["onChange", (e, _arg24) => {
            const v = Browser_Types_Event__Event_get_Value(e);
            dispatch((v === "None") ? (new EscrowFilters(filterValue.OptionKind, void 0, void 0, filterValue.SpotDenom)) : (() => {
                try {
                    const patternInput = options.find((tupledArg_1) => (v === tupledArg_1[0]))[1];
                    const d2_1 = patternInput[1] | 0;
                    const d1_1 = patternInput[0] | 0;
                    return new EscrowFilters(filterValue.OptionKind, (d1_1 === 0) ? (void 0) : fromInteger(d1_1, true, 2), (d2_1 === 1000) ? (void 0) : fromInteger(d2_1, true, 2), filterValue.SpotDenom);
                }
                catch (matchValue_2) {
                    return filterValue;
                }
            })());
        }], ["children", Interop_reactApi.Children.toArray(Array.from(map((o_1) => {
            if (o_1[0] === "") {
                return SelectItem("None");
            }
            else {
                return SelectItem(o_1[0]);
            }
        }, options)))]])])]])]),
    });
}

export function EscrowCurrencySelectBox(escrowCurrencySelectBoxInputProps) {
    const dispatch = escrowCurrencySelectBoxInputProps.dispatch;
    const filterValue = escrowCurrencySelectBoxInputProps.filterValue;
    const options = escrowCurrencySelectBoxInputProps.options;
    const label = escrowCurrencySelectBoxInputProps.label;
    const patternInput = useFeliz_React__React_useState_Static_1505(0);
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(map((t) => t.symbol, options));
    const spotValueArr = patternInput_1[0];
    const setSpotValueArr = patternInput_1[1];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(true);
    const setIsAll = patternInput_2[1];
    const isAll = patternInput_2[0];
    useReact_useEffect_Z101E1A95(() => {
        startImmediate(singleton.Delay(() => {
            if (patternInput[0] === 0) {
                setIsAll(true);
                dispatch(new EscrowFilters(filterValue.OptionKind, filterValue.DayBefore, filterValue.DayAfter, tail(options)));
                patternInput[1](1);
                return singleton.Zero();
            }
            else if (contains("All", spotValueArr, {
                Equals: (x, y) => (x === y),
                GetHashCode: (x) => stringHash(x),
            })) {
                const newFailterValue = new EscrowFilters(filterValue.OptionKind, filterValue.DayBefore, filterValue.DayAfter, tail(options));
                setIsAll(true);
                dispatch(newFailterValue);
                return singleton.Zero();
            }
            else {
                const newFailterValue_1 = new EscrowFilters(filterValue.OptionKind, filterValue.DayBefore, filterValue.DayAfter, options.filter((o) => contains(o.symbol, spotValueArr, {
                    Equals: (x_1, y_1) => (x_1 === y_1),
                    GetHashCode: (x_1) => stringHash(x_1),
                })));
                setIsAll(false);
                dispatch(newFailterValue_1);
                return singleton.Zero();
            }
        }));
    }, [spotValueArr]);
    return createElement("div", {
        style: {
            minWidth: 160,
            maxWidth: 160,
        },
        className: join(" ", [utils$002Emodule["margin-s-right"]]),
        children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(FormControl, [["variant", "outlined"], ["size", "small"], ["fullWidth", true], ["required", false], ["error", false], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(InputLabel, [["children", label]]), MuiHelpers_createElement(Select, [["label", label], ["multiple", true], ["value", options], ["onChange", (e_1, _arg24) => {
            const v = Browser_Types_Event__Event_get_Value(e_1);
        }], ["children", Interop_reactApi.Children.toArray(Array.from(map((o_2) => {
            const o_1 = o_2;
            const symbol = o_1.symbol;
            return MuiHelpers_createElement(MenuItem, [["key", symbol], ["value", symbol], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Checkbox, [["checked", isAll ? true : contains(symbol, spotValueArr, {
                Equals: (x_2, y_2) => (x_2 === y_2),
                GetHashCode: (x_2) => stringHash(x_2),
            })], ["disabled", (symbol !== "All") ? isAll : false], ["onChange", (e) => {
                if (Browser_Types_Event__Event_get_Checked(e)) {
                    if (symbol === "All") {
                        setSpotValueArr(map((t_1) => t_1.symbol, options));
                    }
                    else {
                        setSpotValueArr(Array_distinct(concat([[symbol], spotValueArr]), {
                            Equals: (x_3, y_3) => (x_3 === y_3),
                            GetHashCode: (x_3) => stringHash(x_3),
                        }));
                    }
                }
                else {
                    setSpotValueArr(spotValueArr.filter((s) => {
                        if (s !== symbol) {
                            return s !== "All";
                        }
                        else {
                            return false;
                        }
                    }));
                }
            }]]), createElement("div", {
                style: {
                    display: "flex",
                },
                children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => ((symbol === "All") ? singleton_1("All") : append(singleton_1(createElement(CurrencyIcon, {
                    depositMint: o_1,
                })), delay(() => singleton_1(createElement("div", {
                    className: join(" ", [utils$002Emodule["margin-xs-left"]]),
                    children: Interop_reactApi.Children.toArray([" " + o_1.symbol]),
                }))))))))),
            })])]]);
        }, options)))]])])]])]),
    });
}

