import { createElement } from "react";

export function Card(cardInputProps) {
    const children = cardInputProps.children;
    return createElement("div", {
        href: "#",
        className: "font-title\n                gap-4\n                font-bold\n                text-exo-primary\n                text-2xl\n                lg:p-10\n                p-10\n                md:px-2\n                md:py-5\n                flex\n                flex-col\n                items-center\n                justify-center\n                outline\n                outline-exo-primary\n                outline-1\n                rounded-md\n                hover:text-exo-background\n                hover:outline-none\n                hover:hover:exo-background\n                transition-colors",
        children: children,
    });
}

