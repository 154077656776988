import { createElement } from "react";
import { createObj } from "../.fable/fable-library.3.1.16/Util.js";
import { empty, singleton, append, delay, toList } from "../.fable/fable-library.3.1.16/Seq.js";
import { value as value_8 } from "../.fable/fable-library.3.1.16/Option.js";
import { Recoil_useRecoilState_Z74A95619 } from "../.fable/Feliz.Recoil.0.19.2/Bindings.fs.js";
import { appTab } from "../Context.fs.js";
import { ApplicationTab } from "../Types.fs.js";
import { RouterModule_nav } from "../.fable/Feliz.Router.3.8.0/Router.fs.js";
import { singleton as singleton_1 } from "../.fable/fable-library.3.1.16/List.js";

export function Button(buttonInputProps) {
    const onClick = buttonInputProps.onClick;
    const externalUrl = buttonInputProps.externalUrl;
    const children = buttonInputProps.children;
    return createElement("a", createObj(toList(delay(() => append((externalUrl != null) ? append(singleton(["target", "_blank"]), delay(() => singleton(["href", value_8(externalUrl)]))) : empty(), delay(() => append(singleton(["className", "text-exo-primary\n                hover:text-exo-background\n                flex\n                justify-center\n                items-center\n                gap-3\n                w-52\n                outline\n                outline-exo-primary\n                outline-1\n                rounded-md\n                p-3\n                hover:outline-none\n                hover:exo-background\n                transition-colors\n                cursor-pointer"]), delay(() => append(singleton(["children", children]), delay(() => ((onClick != null) ? singleton(["onClick", value_8(onClick)]) : empty())))))))))));
}

export function LinkToPageButton(linkToPageButtonInputProps) {
    const goToCommunityToggle = linkToPageButtonInputProps.goToCommunityToggle;
    const setGoToCommunityToggle = linkToPageButtonInputProps.setGoToCommunityToggle;
    const setGoToCommunity = linkToPageButtonInputProps.setGoToCommunity;
    const text = linkToPageButtonInputProps.text;
    const setAppTab = Recoil_useRecoilState_Z74A95619(appTab)[1];
    return createElement(Button, {
        children: text,
        externalUrl: void 0,
        onClick: (_arg1) => {
            switch (text) {
                case "Earn Yield": {
                    setAppTab(new ApplicationTab(1));
                    RouterModule_nav(singleton_1("products"), 1, 1);
                    setGoToCommunity(false);
                    break;
                }
                case "Buy Options": {
                    setAppTab(new ApplicationTab(2));
                    RouterModule_nav(singleton_1("optionality"), 1, 1);
                    setGoToCommunity(false);
                    break;
                }
                case "Join The Community": {
                    setGoToCommunity(true);
                    setGoToCommunityToggle(!goToCommunityToggle);
                    break;
                }
                default: {
                }
            }
        },
    });
}

export function LinkToExternalButton(linkToExternalButtonInputProps) {
    const url = linkToExternalButtonInputProps.url;
    const children = linkToExternalButtonInputProps.children;
    return createElement(Button, {
        children: children,
        externalUrl: url,
        onClick: void 0,
    });
}

export function WalletListButton(walletListButtonInputProps) {
    const onClick = walletListButtonInputProps.onClick;
    const text = walletListButtonInputProps.text;
    return createElement(Button, {
        children: text,
        externalUrl: void 0,
        onClick: onClick,
    });
}

