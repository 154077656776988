import { toString, FSharpRef, Union, Record } from "./.fable/fable-library.3.1.16/Types.js";
import { union_type, record_type, float64_type, string_type } from "./.fable/fable-library.3.1.16/Reflection.js";
import { createElement } from "react";
import { printf, toText, join } from "./.fable/fable-library.3.1.16/String.js";
import utils$002Emodule from "./styles/utils.module.scss";
import { toNumber, op_Addition, op_Subtraction, equals, compare, op_Division, op_Multiply, fromParts, tryParse } from "./.fable/fable-library.3.1.16/Decimal.js";
import Decimal from "./.fable/fable-library.3.1.16/Decimal.js";
import { useReact_useEffect_Z101E1A95, useReact_useMemo_CF4EA67, useFeliz_React__React_useState_Static_1505 } from "./.fable/Feliz.1.62.0/React.fs.js";
import { InjectiveProduct__GetStrikeType, InjectiveProduct__GetPercentageStrikeWithSpot_32C73145, InjectiveProduct__GetStrikeWithSpot_32C73145 } from "../../../lib/Domain/Types/InjectiveProduct.fs.js";
import { toDecimal } from "./.fable/fable-library.3.1.16/BigInt.js";
import { convertToRateDecimalFromAPR, FPDECIMAL_ONE } from "../../../lib/Domain/Types/Common.fs.js";
import { Point as Point_1 } from "./FixedStrikedChart.fs.js";
import { empty, singleton, toArray, ofArray } from "./.fable/fable-library.3.1.16/List.js";
import { estimatedInjectiveHoldingYield } from "../../../lib/Injective/Injective.fs.js";
import { equals as equals_1, createObj, int32ToString, max } from "./.fable/fable-library.3.1.16/Util.js";
import { Interop_reactApi } from "./.fable/Feliz.1.62.0/Interop.fs.js";
import { ResponsiveContainer, Area, CartesianGrid, Tooltip, YAxis, XAxis, AreaChart } from "recharts";
import { defaultArg } from "./.fable/fable-library.3.1.16/Option.js";
import { map } from "./.fable/fable-library.3.1.16/Array.js";
import { singleton as singleton_1, append, delay, toList } from "./.fable/fable-library.3.1.16/Seq.js";
import single$002Dproducts$002Emodule from "./styles/single-products.module.scss";
import { Col, Row } from "./UtilComponents.fs.js";
import { SimulateSpotLevel, SimulateSpotLevelRealValue } from "./SpotLevel.fs.js";
import { CurrencyInput } from "./Currency.fs.js";
import * as accounting from "accounting";
import { ProductStart, ProductLifeCycle } from "./ProductLifecyle.fs.js";
import { addSeconds } from "./.fable/fable-library.3.1.16/DateOffset.js";
import { toNumber as toNumber_1 } from "./.fable/fable-library.3.1.16/Long.js";
import { startImmediate } from "./.fable/fable-library.3.1.16/Async.js";
import { singleton as singleton_2 } from "./.fable/fable-library.3.1.16/AsyncBuilder.js";
import { parse } from "./.fable/fable-library.3.1.16/Double.js";

export class Point extends Record {
    constructor(name, uv) {
        super();
        this.name = name;
        this.uv = uv;
    }
}

export function Point$reflection() {
    return record_type("InjectiveHoldingSettlementRules.Point", [], Point, () => [["name", string_type], ["uv", float64_type]]);
}

export class Scenario extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["AboveSpot", "BelowSpot"];
    }
}

export function Scenario$reflection() {
    return union_type("InjectiveHoldingSettlementRules.Scenario", [], Scenario, () => [[], []]);
}

export function text(t) {
    return createElement("span", {
        className: join(" ", [utils$002Emodule["text-dark"]]),
        children: t,
    });
}

export function textBold(t) {
    return createElement("span", {
        className: join(" ", [utils$002Emodule["text-dark"], utils$002Emodule["boldness-500"]]),
        children: t,
    });
}

export function parseDecimal(str) {
    let matchValue;
    let outArg = new Decimal(0);
    matchValue = [tryParse(str, new FSharpRef(() => outArg, (v) => {
        outArg = v;
    })), outArg];
    if (matchValue[0]) {
        return matchValue[1];
    }
    else {
        return fromParts(100, 0, 0, false, 0);
    }
}

export function SettlementRulesGroup(settlementRulesGroupInputProps) {
    let matchValue_2, op_1, x, deposit, children, color;
    const uiFormat = settlementRulesGroupInputProps.uiFormat;
    const spot = settlementRulesGroupInputProps.spot;
    const spotToken = settlementRulesGroupInputProps.spotToken;
    const underlyingToken = settlementRulesGroupInputProps.underlyingToken;
    const holdingProduct = settlementRulesGroupInputProps.holdingProduct;
    try {
        const patternInput = useFeliz_React__React_useState_Static_1505(spot);
        const setSpotLevel = patternInput[1];
        const initSpot = spot;
        const spotLevel_1 = patternInput[0];
        const patternInput_1 = useFeliz_React__React_useState_Static_1505("100");
        const amount = patternInput_1[0];
        const amountSimulation = useReact_useMemo_CF4EA67(() => parseDecimal(amount), [amount]);
        const currentPrice = spot;
        let fixedStrike;
        const matchValue = holdingProduct.Option;
        fixedStrike = ((matchValue != null) ? matchValue.Strike : InjectiveProduct__GetStrikeWithSpot_32C73145(holdingProduct.Product, currentPrice));
        const initialSpotSlider = fromParts(100, 0, 0, false, 0);
        const patternInput_2 = useFeliz_React__React_useState_Static_1505(initialSpotSlider);
        const setCurrentSpotSlider = patternInput_2[1];
        const currentSpotSlider = patternInput_2[0];
        const settlementPrice = useReact_useMemo_CF4EA67(() => op_Multiply(currentPrice, op_Division(currentSpotSlider, fromParts(1000, 0, 0, false, 1))), [currentSpotSlider, holdingProduct]);
        const amountSimulationChecked = useReact_useMemo_CF4EA67(() => ((compare(amountSimulation, fromParts(0, 0, 0, false, 1)) <= 0) ? fromParts(100, 0, 0, false, 0) : amountSimulation), [amountSimulation, holdingProduct]);
        const chooseScenario = useReact_useMemo_CF4EA67(() => ((compare(settlementPrice, fixedStrike) >= 0) ? (new Scenario(0)) : (new Scenario(1))), [currentSpotSlider]);
        const patternInput_3 = [holdingProduct.Product.ProductType.fields[0].IsPut, (matchValue_2 = holdingProduct.Option, (matchValue_2 == null) ? InjectiveProduct__GetPercentageStrikeWithSpot_32C73145(holdingProduct.Product, spotLevel_1) : (op_1 = matchValue_2, (() => {
            try {
                return op_Multiply(op_Division(op_Division(op_1.Strike, toDecimal(FPDECIMAL_ONE)), spot), fromParts(100, 0, 0, false, 0));
            }
            catch (matchValue_3) {
                return InjectiveProduct__GetPercentageStrikeWithSpot_32C73145(holdingProduct.Product, spotLevel_1);
            }
        })()))];
        const isPut = patternInput_3[0];
        let data;
        const spotLevel_2 = spotLevel_1;
        data = ofArray([new Point_1("Start Product", spotLevel_2, spotLevel_2), new Point_1("", op_Multiply(spotLevel_2, fromParts(111, 0, 0, false, 2)), op_Multiply(spotLevel_2, fromParts(115, 0, 0, false, 2))), new Point_1("", op_Multiply(fixedStrike, fromParts(97, 0, 0, false, 2)), op_Multiply(spotLevel_2, fromParts(93, 0, 0, false, 2))), new Point_1("Product End", op_Multiply(fixedStrike, fromParts(107, 0, 0, false, 2)), op_Multiply(fixedStrike, fromParts(96, 0, 0, false, 2)))]);
        const amountToBeReceived = () => {
            const spot_1 = fromParts(100, 0, 0, false, 0);
            const premium = equals(currentPrice, fromParts(0, 0, 0, false, 0)) ? fromParts(0, 0, 0, false, 0) : op_Multiply(amountSimulationChecked, convertToRateDecimalFromAPR(holdingProduct.Product.Duration, estimatedInjectiveHoldingYield(holdingProduct.Product, holdingProduct.Holding.FairPrice, currentPrice, fixedStrike)));
            const strike = op_Division(patternInput_3[1], fromParts(100, 0, 0, false, 0));
            const fakeAbsStrike = op_Multiply(spot_1, strike);
            const currentExpirySlider = op_Division(currentSpotSlider, fromParts(100, 0, 0, false, 0));
            const fakeAbsSpot = op_Multiply(spot_1, currentExpirySlider);
            if (!isPut) {
                return [premium, (compare(op_Subtraction(currentExpirySlider, strike), fromParts(0, 0, 0, false, 0)) > 0) ? op_Addition(premium, op_Division(op_Multiply(amountSimulationChecked, fakeAbsStrike), fakeAbsSpot)) : op_Addition(premium, amountSimulationChecked)];
            }
            else {
                return [premium, op_Addition(premium, op_Multiply(amountSimulationChecked, op_Subtraction(fromParts(10, 0, 0, false, 1), max((x_2, y) => compare(x_2, y), fromParts(0, 0, 0, false, 1), op_Subtraction(fromParts(1, 0, 0, false, 0), op_Division(currentExpirySlider, strike))))))];
            }
        };
        const amountRetrieved = useReact_useMemo_CF4EA67(() => amountToBeReceived()[1], [currentSpotSlider, amount, holdingProduct]);
        const premiumRetrieved = useReact_useMemo_CF4EA67(() => amountToBeReceived()[0], [currentSpotSlider, amount, holdingProduct]);
        const chart = Interop_reactApi.createElement(AreaChart, {
            width: 512,
            height: 120,
            data: toArray((x = premiumRetrieved, (deposit = amountSimulationChecked, (!isPut) ? ofArray([new Point("Below Strike", toNumber(deposit) + toNumber(x)), new Point("Below Strike", toNumber(deposit) + toNumber(x)), new Point("Below Strike", toNumber(deposit) + toNumber(x)), new Point("Below Strike", toNumber(deposit) + toNumber(x)), new Point("At Strike", toNumber(deposit) + toNumber(x)), new Point("Above Strike", (toNumber(deposit) * 0.8) + toNumber(x)), new Point("Above Strike", (toNumber(deposit) * 0.6) + toNumber(x)), new Point("Above Strike", (toNumber(deposit) * 0.4) + toNumber(x))]) : ofArray([new Point("Below Strike", (toNumber(deposit) * 0.4) + toNumber(x)), new Point("Below Strike", (toNumber(deposit) * 0.6) + toNumber(x)), new Point("Below Strike", (toNumber(deposit) * 0.8) + toNumber(x)), new Point("At Strike", toNumber(deposit) + toNumber(x)), new Point("Above Strike", toNumber(deposit) + toNumber(x)), new Point("Above Strike", toNumber(deposit) + toNumber(x)), new Point("Above Strike", toNumber(deposit) + toNumber(x)), new Point("Above Strike", toNumber(deposit) + toNumber(x))])))),
            margin: {
                top: defaultArg(10, 0),
                right: defaultArg(30, 0),
                left: defaultArg(void 0, 0),
                bottom: defaultArg(void 0, 0),
            },
            children: Interop_reactApi.Children.toArray([(children = singleton((color = "#8884d8", createElement("linearGradient", {
                id: "colorUv",
                x1: 0,
                x2: 1,
                y1: 0,
                y2: 1,
                children: Interop_reactApi.Children.toArray([createElement("stop", {
                    offset: 0 + "%",
                    stopColor: color,
                    stopOpacity: 0.8,
                }), createElement("stop", {
                    offset: 100 + "%",
                    stopColor: color,
                    stopOpacity: 0,
                })]),
            }))), createElement("defs", {
                children: Interop_reactApi.Children.toArray(Array.from(children)),
            })), Interop_reactApi.createElement(XAxis, {
                dataKey: (point) => point.name,
                hide: true,
            }), Interop_reactApi.createElement(YAxis, {}), Interop_reactApi.createElement(Tooltip, {}), Interop_reactApi.createElement(CartesianGrid, {
                strokeDasharray: join(" ", map((value_35) => int32ToString(value_35), new Int32Array([3, 3]))),
            }), Interop_reactApi.createElement(Area, {
                type: "monotone",
                dataKey: (point_1) => point_1.uv,
                stroke: "#8884d8",
                fillOpacity: 1,
                fill: "url(#colorUv)",
            })]),
        });
        return createElement("div", {
            className: join(" ", [utils$002Emodule["margin-m-top"]]),
            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                let value_52, arg10;
                return append(singleton_1(createElement("div", {
                    className: join(" ", [utils$002Emodule["margin-s-top"], single$002Dproducts$002Emodule["modal-chart"]]),
                    children: Interop_reactApi.Children.toArray([createElement("div", {
                        className: join(" ", [utils$002Emodule["margin-xs-left"]]),
                        children: Interop_reactApi.Children.toArray([createElement(Row, {
                            classes: ofArray([utils$002Emodule["gap-s"], utils$002Emodule["text-xs"]]),
                            children: singleton("Payoff at expiry"),
                        }), Interop_reactApi.createElement(ResponsiveContainer, createObj(ofArray([["height", 100 + "%"], (value_52 = (100 + "%"), (equals_1(value_52, 100 + "%") ? true : equals_1(value_52, 100 + "%")) ? ["width", 99 + "%"] : ["width", value_52]), ["minWidth", 512], ["minHeight", 110], ["children", chart]]))), createElement(Row, {
                            classes: ofArray([utils$002Emodule["gap-s"], utils$002Emodule["text-xs"], utils$002Emodule["margin-xl-left"]]),
                            children: singleton((arg10 = spotToken.symbol, toText(printf("%s Price at Expiry"))(arg10))),
                        })]),
                    })]),
                })), delay(() => {
                    let arg10_1;
                    return append(singleton_1(createElement(Row, {
                        classes: ofArray([utils$002Emodule["text-m"], utils$002Emodule["boldness-600"], utils$002Emodule["margin-s-bottom"], utils$002Emodule["margin-m-top"], single$002Dproducts$002Emodule["modal-simulate-spotlevel"]]),
                        children: singleton((arg10_1 = spotToken.symbol, toText(printf("Simulate %s Price at Expiry"))(arg10_1))),
                    })), delay(() => {
                        let matchValue_5;
                        return append((holdingProduct.Option == null) ? (matchValue_5 = InjectiveProduct__GetStrikeType(holdingProduct.Product), (matchValue_5.tag === 1) ? singleton_1(createElement(SimulateSpotLevelRealValue, {
                            classes: [utils$002Emodule["margin-l-bottom"], utils$002Emodule["margin-s-top"]],
                            spot: initSpot,
                            setSpotLevel: setSpotLevel,
                            initialSpot: initialSpotSlider,
                            expirtSpot: currentSpotSlider,
                            setExpirySpot: setCurrentSpotSlider,
                            spotToken: spotToken,
                            uiFormat: uiFormat,
                        })) : singleton_1(createElement(SimulateSpotLevel, {
                            classes: [utils$002Emodule["margin-l-bottom"], utils$002Emodule["margin-s-top"]],
                            spot: initSpot,
                            setSpotLevel: setSpotLevel,
                            initial: initialSpotSlider,
                            expirtSpot: currentSpotSlider,
                            setExpirySpot: setCurrentSpotSlider,
                        }))) : singleton_1(createElement(SimulateSpotLevelRealValue, {
                            classes: [utils$002Emodule["margin-l-bottom"], utils$002Emodule["margin-s-top"]],
                            spot: initSpot,
                            setSpotLevel: setSpotLevel,
                            initialSpot: initialSpotSlider,
                            expirtSpot: currentSpotSlider,
                            setExpirySpot: setCurrentSpotSlider,
                            spotToken: spotToken,
                            uiFormat: uiFormat,
                        })), delay(() => {
                            let arg10_2, arg20, arg10_3;
                            return append(singleton_1(createElement("div", {
                                className: join(" ", [single$002Dproducts$002Emodule["modal-simulate"]]),
                                children: Interop_reactApi.Children.toArray([createElement(Col, {
                                    classes: singleton(utils$002Emodule["align-center"]),
                                    children: singleton("Amount deposited:"),
                                }), createElement("div", {
                                    children: Interop_reactApi.Children.toArray([createElement(CurrencyInput, {
                                        mint: underlyingToken,
                                        value: amount,
                                        dispatch: patternInput_1[1],
                                        error: void 0,
                                    })]),
                                }), createElement("div", {}), createElement("div", {
                                    children: Interop_reactApi.Children.toArray([createElement("div", {
                                        children: Interop_reactApi.Children.toArray(["At expiry, you will get:"]),
                                    }), createElement(Row, {
                                        classes: empty(),
                                        children: ofArray([createElement(Col, {
                                            classes: ofArray([utils$002Emodule["boldness-500"], utils$002Emodule["margin-xs-top"]]),
                                            children: singleton((arg10_2 = accounting.formatMoney(toNumber(amountRetrieved), ""), toText(printf("%s"))(arg10_2))),
                                        }), createElement(Col, {
                                            classes: ofArray([utils$002Emodule["margin-xs-left"], utils$002Emodule["margin-xs-top"]]),
                                            children: singleton(underlyingToken.symbol),
                                        }), createElement(Col, {
                                            classes: ofArray([utils$002Emodule["margin-xs-left"], utils$002Emodule["margin-xs-top"]]),
                                            children: singleton((arg20 = underlyingToken.symbol, (arg10_3 = accounting.formatMoney(toNumber(premiumRetrieved), ""), toText(printf(" (%s %s as yield)"))(arg10_3)(arg20)))),
                                        })]),
                                    })]),
                                })]),
                            })), delay(() => append(singleton_1(createElement(Row, {
                                classes: ofArray([utils$002Emodule["text-m"], utils$002Emodule["boldness-600"], utils$002Emodule["margin-s-bottom"], utils$002Emodule["margin-l-top"], single$002Dproducts$002Emodule["modal-simulate-spotlevel"]]),
                                children: singleton("Product Lifecycle (Local Time)"),
                            })), delay(() => singleton_1(createElement(Row, {
                                classes: ofArray([utils$002Emodule.center, utils$002Emodule["boldness-500"], utils$002Emodule["margin-s-top"]]),
                                children: singleton(createElement(ProductLifeCycle, {
                                    subscriptionStart: holdingProduct.Holding.StartCollection,
                                    subscriptionEnd: holdingProduct.Holding.EndCollection,
                                    productStart: new ProductStart(0, holdingProduct.Holding.EndCollection),
                                    productEnd: addSeconds(holdingProduct.Holding.StartOption, toNumber_1(holdingProduct.Product.Duration)),
                                })),
                            }))))));
                        }));
                    }));
                }));
            })))),
        });
    }
    catch (matchValue_6) {
        return null;
    }
}

export function OptionSettlementRulesGroup(optionSettlementRulesGroupInputProps) {
    let matchValue_2, op_1, x, deposit, children, color, value_52, arg10, arg10_1, arg10_2, arg10_3;
    const uiFormat = optionSettlementRulesGroupInputProps.uiFormat;
    const unitBuy = optionSettlementRulesGroupInputProps.unitBuy;
    const unitPrice = optionSettlementRulesGroupInputProps.unitPrice;
    const spot = optionSettlementRulesGroupInputProps.spot;
    const spotToken = optionSettlementRulesGroupInputProps.spotToken;
    const underlyingToken = optionSettlementRulesGroupInputProps.underlyingToken;
    const holdingProduct = optionSettlementRulesGroupInputProps.holdingProduct;
    try {
        const patternInput = useFeliz_React__React_useState_Static_1505(spot);
        const spotLevel_1 = patternInput[0];
        const patternInput_1 = useFeliz_React__React_useState_Static_1505(unitBuy);
        const setAmount = patternInput_1[1];
        const amount = patternInput_1[0];
        useReact_useEffect_Z101E1A95(() => {
            startImmediate(singleton_2.Delay(() => {
                if (parse(unitBuy) === 0) {
                    setAmount("100");
                    return singleton_2.Zero();
                }
                else {
                    setAmount(unitBuy);
                    return singleton_2.Zero();
                }
            }));
        }, [unitBuy]);
        const amountSimulation = useReact_useMemo_CF4EA67(() => parseDecimal(amount), [amount]);
        const currentPrice = spot;
        let patternInput_2;
        const matchValue = holdingProduct.Option;
        if (matchValue != null) {
            const op = matchValue;
            patternInput_2 = [op.Strike, op.OptionExpiry];
        }
        else {
            patternInput_2 = [InjectiveProduct__GetStrikeWithSpot_32C73145(holdingProduct.Product, currentPrice), addSeconds(holdingProduct.Holding.StartOption, toNumber_1(holdingProduct.Product.Duration))];
        }
        const fixedStrike = patternInput_2[0];
        const initialSpotSlider = fromParts(100, 0, 0, false, 0);
        const patternInput_3 = useFeliz_React__React_useState_Static_1505(initialSpotSlider);
        const currentSpotSlider = patternInput_3[0];
        const settlementPrice = useReact_useMemo_CF4EA67(() => op_Multiply(currentPrice, op_Division(currentSpotSlider, fromParts(1000, 0, 0, false, 1))), [currentSpotSlider, holdingProduct]);
        const amountSimulationChecked = useReact_useMemo_CF4EA67(() => ((compare(amountSimulation, fromParts(0, 0, 0, false, 1)) <= 0) ? fromParts(100, 0, 0, false, 0) : amountSimulation), [amountSimulation, holdingProduct]);
        const chooseScenario = useReact_useMemo_CF4EA67(() => ((compare(settlementPrice, fixedStrike) >= 0) ? (new Scenario(0)) : (new Scenario(1))), [currentSpotSlider]);
        const patternInput_4 = [holdingProduct.Product.ProductType.fields[0].IsPut, (matchValue_2 = holdingProduct.Option, (matchValue_2 == null) ? InjectiveProduct__GetPercentageStrikeWithSpot_32C73145(holdingProduct.Product, spotLevel_1) : (op_1 = matchValue_2, (() => {
            try {
                return op_Multiply(op_Division(op_Division(op_1.Strike, toDecimal(FPDECIMAL_ONE)), spot), fromParts(100, 0, 0, false, 0));
            }
            catch (matchValue_3) {
                return InjectiveProduct__GetPercentageStrikeWithSpot_32C73145(holdingProduct.Product, spotLevel_1);
            }
        })()))];
        const isPut = patternInput_4[0];
        let data;
        const spotLevel_2 = spotLevel_1;
        data = ofArray([new Point_1("Start Product", spotLevel_2, spotLevel_2), new Point_1("", op_Multiply(spotLevel_2, fromParts(111, 0, 0, false, 2)), op_Multiply(spotLevel_2, fromParts(115, 0, 0, false, 2))), new Point_1("", op_Multiply(fixedStrike, fromParts(97, 0, 0, false, 2)), op_Multiply(spotLevel_2, fromParts(93, 0, 0, false, 2))), new Point_1("Product End", op_Multiply(fixedStrike, fromParts(107, 0, 0, false, 2)), op_Multiply(fixedStrike, fromParts(96, 0, 0, false, 2)))]);
        const amountToBeReceived = () => {
            const spot_1 = fromParts(100, 0, 0, false, 0);
            const premium = op_Multiply(unitPrice, amountSimulationChecked);
            const strike = op_Division(patternInput_4[1], fromParts(100, 0, 0, false, 0));
            const fakeAbsStrike = op_Multiply(spot_1, strike);
            const currentExpirySlider = op_Division(currentSpotSlider, fromParts(100, 0, 0, false, 0));
            const fakeAbsSpot = op_Multiply(spot_1, currentExpirySlider);
            if (!isPut) {
                return [premium, (compare(op_Subtraction(currentExpirySlider, strike), fromParts(0, 0, 0, false, 0)) > 0) ? op_Multiply(amountSimulationChecked, op_Subtraction(fromParts(1, 0, 0, false, 0), op_Division(fakeAbsStrike, fakeAbsSpot))) : fromParts(0, 0, 0, false, 0)];
            }
            else {
                return [premium, op_Multiply(amountSimulationChecked, max((x_2, y) => compare(x_2, y), fromParts(0, 0, 0, false, 1), op_Subtraction(fromParts(1, 0, 0, false, 0), op_Division(fakeAbsSpot, fakeAbsStrike))))];
            }
        };
        const amountRetrieved = useReact_useMemo_CF4EA67(() => amountToBeReceived()[1], [currentSpotSlider, amount, holdingProduct]);
        const premiumRetrieved = useReact_useMemo_CF4EA67(() => amountToBeReceived()[0], [currentSpotSlider, amount, holdingProduct]);
        const chart = Interop_reactApi.createElement(AreaChart, {
            width: 512,
            height: 120,
            data: toArray((x = premiumRetrieved, (deposit = amountSimulationChecked, (!isPut) ? ofArray([new Point("Below Strike", -toNumber(x)), new Point("Below Strike", -toNumber(x)), new Point("Below Strike", -toNumber(x)), new Point("Below Strike", -toNumber(x)), new Point("At Strike", -toNumber(x)), new Point("Above Strike", (toNumber(deposit) * 0.2) - toNumber(x)), new Point("Above Strike", (toNumber(deposit) * 0.4) - toNumber(x)), new Point("Above Strike", (toNumber(deposit) * 0.6) - toNumber(x))]) : ofArray([new Point("Below Strike", (toNumber(deposit) * 0.6) - toNumber(x)), new Point("Below Strike", (toNumber(deposit) * 0.4) - toNumber(x)), new Point("Below Strike", (toNumber(deposit) * 0.2) - toNumber(x)), new Point("At Strike", -toNumber(x)), new Point("Above Strike", -toNumber(x)), new Point("Above Strike", -toNumber(x)), new Point("Above Strike", -toNumber(x)), new Point("Above Strike", -toNumber(x))])))),
            margin: {
                top: defaultArg(10, 0),
                right: defaultArg(30, 0),
                left: defaultArg(void 0, 0),
                bottom: defaultArg(void 0, 0),
            },
            children: Interop_reactApi.Children.toArray([(children = singleton((color = "#8884d8", createElement("linearGradient", {
                id: "colorUv",
                x1: 0,
                x2: 1,
                y1: 0,
                y2: 1,
                children: Interop_reactApi.Children.toArray([createElement("stop", {
                    offset: 0 + "%",
                    stopColor: color,
                    stopOpacity: 0.8,
                }), createElement("stop", {
                    offset: 100 + "%",
                    stopColor: color,
                    stopOpacity: 0,
                })]),
            }))), createElement("defs", {
                children: Interop_reactApi.Children.toArray(Array.from(children)),
            })), Interop_reactApi.createElement(XAxis, {
                dataKey: (point) => point.name,
                hide: true,
            }), Interop_reactApi.createElement(YAxis, {}), Interop_reactApi.createElement(Tooltip, {}), Interop_reactApi.createElement(CartesianGrid, {
                strokeDasharray: join(" ", map((value_35) => int32ToString(value_35), new Int32Array([3, 3]))),
            }), Interop_reactApi.createElement(Area, {
                type: "monotone",
                dataKey: (point_1) => point_1.uv,
                stroke: "#8884d8",
                fillOpacity: 1,
                fill: "url(#colorUv)",
            })]),
        });
        return createElement("div", {
            className: join(" ", [utils$002Emodule["margin-m-top"]]),
            children: Interop_reactApi.Children.toArray([createElement("div", {
                className: join(" ", [utils$002Emodule["margin-s-top"], single$002Dproducts$002Emodule["modal-chart"]]),
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    className: join(" ", [utils$002Emodule["margin-xs-left"]]),
                    children: Interop_reactApi.Children.toArray([createElement(Row, {
                        classes: ofArray([utils$002Emodule["gap-s"], utils$002Emodule["text-xs"]]),
                        children: singleton("Payoff at expiry"),
                    }), Interop_reactApi.createElement(ResponsiveContainer, createObj(ofArray([["height", 100 + "%"], (value_52 = (100 + "%"), (equals_1(value_52, 100 + "%") ? true : equals_1(value_52, 100 + "%")) ? ["width", 99 + "%"] : ["width", value_52]), ["minWidth", 512], ["minHeight", 110], ["children", chart]]))), createElement(Row, {
                        classes: ofArray([utils$002Emodule["gap-s"], utils$002Emodule["text-xs"], utils$002Emodule["margin-xl-left"]]),
                        children: singleton((arg10 = spotToken.symbol, toText(printf("%s Price at Expiry"))(arg10))),
                    })]),
                })]),
            }), createElement(Row, {
                classes: ofArray([utils$002Emodule["text-m"], utils$002Emodule["boldness-600"], utils$002Emodule["margin-s-bottom"], utils$002Emodule["margin-m-top"], single$002Dproducts$002Emodule["modal-simulate-spotlevel"]]),
                children: singleton((arg10_1 = spotToken.symbol, toText(printf("Simulate %s Price at Expiry ($)"))(arg10_1))),
            }), createElement(SimulateSpotLevelRealValue, {
                classes: [utils$002Emodule["margin-l-bottom"], utils$002Emodule["margin-s-top"]],
                spot: spot,
                setSpotLevel: patternInput[1],
                initialSpot: initialSpotSlider,
                expirtSpot: currentSpotSlider,
                setExpirySpot: patternInput_3[1],
                spotToken: spotToken,
                uiFormat: uiFormat,
            }), createElement("div", {
                className: join(" ", [single$002Dproducts$002Emodule["modal-simulate-option"]]),
                children: Interop_reactApi.Children.toArray([createElement(Col, {
                    classes: singleton(utils$002Emodule["align-center"]),
                    children: singleton("Unit You Buy:"),
                }), createElement("div", {
                    children: Interop_reactApi.Children.toArray([createElement(CurrencyInput, {
                        mint: underlyingToken,
                        value: amount,
                        dispatch: setAmount,
                        error: void 0,
                    })]),
                }), createElement("div", {}), createElement("div", {
                    children: Interop_reactApi.Children.toArray([createElement("div", {
                        children: Interop_reactApi.Children.toArray(["At expiry, you will get:"]),
                    }), createElement(Row, {
                        classes: empty(),
                        children: ofArray([createElement(Col, {
                            classes: ofArray([utils$002Emodule["boldness-500"], utils$002Emodule["margin-xs-top"]]),
                            children: singleton((arg10_2 = accounting.formatMoney(toNumber(amountRetrieved), ""), toText(printf("%s"))(arg10_2))),
                        }), createElement(Col, {
                            classes: ofArray([utils$002Emodule["margin-xs-left"], utils$002Emodule["margin-xs-top"]]),
                            children: singleton(underlyingToken.symbol),
                        })]),
                    })]),
                })]),
            }), createElement(Row, {
                classes: ofArray([utils$002Emodule["text-s"], utils$002Emodule["boldness-300"], utils$002Emodule["margin-s-bottom"], utils$002Emodule["margin-l-top"], single$002Dproducts$002Emodule["modal-simulate-spotlevel"]]),
                children: singleton((arg10_3 = toString(patternInput_2[1]), toText(printf("Option Expiry (Local Time): %s"))(arg10_3))),
            })]),
        });
    }
    catch (matchValue_4) {
        return null;
    }
}

