import { createElement } from "react";
import { Interop_reactApi } from "../../.fable/Feliz.1.62.0/Interop.fs.js";

export function BurgerMenu(burgerMenuInputProps) {
    const isOpen = burgerMenuInputProps.isOpen;
    return createElement("svg", {
        width: 40,
        height: 24,
        viewBox: (((((0 + " ") + 0) + " ") + 40) + " ") + 24,
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
        children: Interop_reactApi.Children.toArray([createElement("path", {
            d: "M0 2H40",
            stroke: "currentColor",
            strokeWidth: 3,
        }), createElement("path", {
            d: "M0 12H40",
            stroke: "currentColor",
            strokeWidth: 3,
        }), createElement("path", {
            d: "M0 22H40",
            stroke: "currentColor",
            strokeWidth: 3,
        })]),
    });
}

