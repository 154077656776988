import single$002Dproducts$002Emodule from "./styles/single-products.module.scss";
import { createElement } from "react";
import * as react from "react";
import { interpolate, toText, endsWith, join } from "./.fable/fable-library.3.1.16/String.js";
import utils$002Emodule from "./styles/utils.module.scss";
import { Interop_reactApi } from "./.fable/Feliz.1.62.0/Interop.fs.js";
import { ErrorText, Content, ButtonProgressLoader, Row, Col, Column, TextColumn, SortableHeader, Header } from "./UtilComponents.fs.js";
import { empty as empty_1, head as head_1, toArray, singleton as singleton_1, ofArray } from "./.fable/fable-library.3.1.16/List.js";
import { SortOrder, SortOrderType, FPDECIMAL_ONE, SortOrder__Toggle } from "../../../lib/Domain/Types/Common.fs.js";
import { map, value as value_83, defaultArg } from "./.fable/fable-library.3.1.16/Option.js";
import { concat, choose as choose_1, map as map_1, take, skip, sortBy, sortByDescending, contains, tryFind, head } from "./.fable/fable-library.3.1.16/Array.js";
import { Escrow__GetBalanceDecimal_Z524259A4, Escrow__GetTotalNumberOfPositionTokenMintedDecimal, Escrow__GetNbOptionDecimal } from "../../../lib/Domain/Types/InjectiveEscrow.fs.js";
import { toNumber, op_Addition, op_Subtraction as op_Subtraction_1, op_Multiply, parse, pow, compare, fromParts, equals, op_Division } from "./.fable/fable-library.3.1.16/Decimal.js";
import { toDecimal } from "./.fable/fable-library.3.1.16/BigInt.js";
import { toNumber as toNumber_1, toString, fromNumber, fromInteger, op_Division as op_Division_1 } from "./.fable/fable-library.3.1.16/Long.js";
import { addDays, utcNow, op_Subtraction } from "./.fable/fable-library.3.1.16/DateOffset.js";
import { chainGrpcBankApi, getAddressBalancesWithPagination, getEscrowArrFromStringArr, getNetworkByInjectiveEnvironment, useWalletStore, chainGrpcWasmApi, getWasmContractsListQuery, estimatedInjectiveHoldingYieldForEscrowVolatilityPrice } from "../../../lib/Injective/Injective.fs.js";
import { totalSeconds } from "./.fable/fable-library.3.1.16/TimeSpan.js";
import { InjectiveProduct__GetStrikeWithSpot_32C73145, InjectiveProduct_GetEscrowProductName, InjectiveProduct__GetKind } from "../../../lib/Domain/Types/InjectiveProduct.fs.js";
import { Option__GetCollateralAmount } from "../../../lib/Domain/Types/InjectiveOption.fs.js";
import { Rfq__GetSelectedBid } from "../../../lib/Domain/Types/InjectiveRfq.fs.js";
import { floorDecimal } from "./BlockChainInteraction/InjectiveHoldingDeposit.fs.js";
import { map as map_2, choose, toArray as toArray_1, empty, singleton, append, delay, toList } from "./.fable/fable-library.3.1.16/Seq.js";
import { TimeCountdown } from "./DisplayTime.fs.js";
import { Recoil_useRecoilValue_5D3EEEEA, Recoil_useRecoilState_Z74A95619 } from "./.fable/Feliz.Recoil.0.19.2/Bindings.fs.js";
import { warningMessageShown as warningMessageShown_1, generalMessageShown as generalMessageShown_1, blockchain, config as config_2, injectiveEnvironment as injectiveEnvironment_1, tokens as tokens_11 } from "./Context.fs.js";
import { stringHash, comparePrimitives, structuralHash, equals as equals_1, createObj } from "./.fable/fable-library.3.1.16/Util.js";
import { sleep, startAsPromise, startImmediate } from "./.fable/fable-library.3.1.16/Async.js";
import { singleton as singleton_2 } from "./.fable/fable-library.3.1.16/AsyncBuilder.js";
import { ProductDataTokens, processInjectiveEscrowDataOptionality, processInjectiveHoldingProductDataDashboard, processInjectiveEscrowData } from "./HoldingData.fs.js";
import { FSharpResult$2, Result_Map } from "./.fable/fable-library.3.1.16/Choice.js";
import { VaultComingSoon, manageLiquidity } from "./Img.fs.js";
import { useReact_useEffect_Z101E1A95, useReact_useCallback_93353E, useFeliz_React__React_useState_Static_1505 } from "./.fable/Feliz.1.62.0/React.fs.js";
import { Deferred$1 } from "./.fable/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { EscrowCurrencySelectBox, EscrowExpirySelectBox, EscrowOptionKindSelectBox, EscrowFilters } from "./Filtering.fs.js";
import { toString as toString_1 } from "./.fable/fable-library.3.1.16/Types.js";
import { compare as compare_1 } from "./.fable/fable-library.3.1.16/Date.js";
import { getNetworkEndpoints } from "@injectivelabs/networks";
import { getOnChainHoldingOptionRfqData, getOnChainHoldingProductData } from "./SingleProducts.fs.js";
import { Api } from "./Server.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "./.fable/Fable.Promise.2.2.2/Promise.fs.js";
import { promise } from "./.fable/Fable.Promise.2.2.2/PromiseImpl.fs.js";
import { fetchInjectiveToken } from "../../../lib/Solana/Utils.fs.js";
import { NoData } from "./Dashboard.fs.js";
import { RouterModule_nav } from "./.fable/Feliz.Router.3.8.0/Router.fs.js";
import { Array_distinct } from "./.fable/fable-library.3.1.16/Seq2.js";
import { createToken } from "../../../lib/Solana/Token.fs.js";
import Refresh from "@material-ui/icons/Refresh";
import { MuiHelpers_createElement } from "./.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ErrorMessage } from "./Error.fs.js";
import { PagingRow } from "./InjectiveLeaderboard.fs.js";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { ConnectWalletMessage, ConnectInjectiveWalletMessage } from "./ConnectWalletMessage.fs.js";
import { OptionalityDetailWithoutEscrow, OptionalityDetail } from "./OptionalityDetail.fs.js";

export function OptionalityHeader(optionalityHeaderInputProps) {
    const sortExpiry = optionalityHeaderInputProps.sortExpiry;
    const expirySortOrder = optionalityHeaderInputProps.expirySortOrder;
    const sortPrice = optionalityHeaderInputProps.sortPrice;
    const priceSortOrder = optionalityHeaderInputProps.priceSortOrder;
    const sortQuantity = optionalityHeaderInputProps.sortQuantity;
    const quantitySortOrder = optionalityHeaderInputProps.quantitySortOrder;
    const buttonHeader = optionalityHeaderInputProps.buttonHeader;
    const rowClass = single$002Dproducts$002Emodule["position-row-current"];
    return createElement("div", {
        className: join(" ", [rowClass, utils$002Emodule["margin-l-top"], utils$002Emodule["padding-m-left"]]),
        children: Interop_reactApi.Children.toArray([createElement(Header, {
            text: "Product",
            classes: ofArray([utils$002Emodule.center, utils$002Emodule["grid-column-1"]]),
        }), createElement(SortableHeader, {
            text: "Available Qty",
            sort: () => {
                sortQuantity(SortOrder__Toggle(quantitySortOrder));
            },
            classes: ofArray([utils$002Emodule.center, utils$002Emodule["grid-column-2"]]),
        }), createElement(Header, {
            text: "Unit Price",
            classes: ofArray([utils$002Emodule.center, utils$002Emodule["grid-column-3"]]),
        }), createElement(SortableHeader, {
            text: "Strike",
            sort: () => {
                sortPrice(SortOrder__Toggle(priceSortOrder));
            },
            classes: ofArray([utils$002Emodule.center, utils$002Emodule["grid-column-4"]]),
        }), createElement(Header, {
            text: "Duration",
            classes: ofArray([utils$002Emodule.center, utils$002Emodule["grid-column-5"]]),
        }), createElement(SortableHeader, {
            text: "Expire In",
            sort: () => {
                sortExpiry(SortOrder__Toggle(expirySortOrder));
            },
            classes: ofArray([utils$002Emodule.center, utils$002Emodule["grid-column-6"]]),
        }), createElement(Header, {
            text: defaultArg(buttonHeader, ""),
            classes: ofArray([utils$002Emodule.center, utils$002Emodule["grid-column-7"]]),
        })]),
    });
}

export function OptionalityRow(optionalityRowInputProps) {
    const button = optionalityRowInputProps.button;
    const oracles = optionalityRowInputProps.oracles;
    const data = optionalityRowInputProps.data;
    const escrowData = head(data)[0];
    const tokens = head(data)[1];
    const product = escrowData.Product.Product;
    let nbOption;
    const arg20 = tokens.Deposit.decimals | 0;
    nbOption = Escrow__GetNbOptionDecimal(escrowData.Escrow, product, value_83(escrowData.Product.Option), arg20);
    const strike = op_Division(value_83(escrowData.Product.Option).Strike, toDecimal(FPDECIMAL_ONE));
    const maturity = op_Division_1(product.Duration, fromInteger(86400, true, 2));
    const ttm = op_Subtraction(value_83(escrowData.Product.Option).OptionExpiry, utcNow());
    const showSoldOut = equals(nbOption, fromParts(0, 0, 0, false, 0)) ? true : (compare(op_Division(toDecimal(escrowData.Escrow.MinQuantity), pow(fromParts(10, 0, 0, false, 0), tokens.Deposit.decimals)), nbOption) > 0);
    let price_2;
    const multiplier = pow(fromParts(10, 0, 0, false, 0), tokens.Deposit.decimals);
    let price_1;
    const matchValue = escrowData.Escrow.Price;
    if (matchValue.tag === 1) {
        price_1 = op_Division(toDecimal(matchValue.fields[0]), multiplier);
    }
    else {
        let spot;
        const matchValue_1 = [map((s) => parse(s.price), tryFind((o) => {
            if (o.symbol === ("0x" + product.OracleBase)) {
                return o.oracleType === "pyth";
            }
            else {
                return false;
            }
        }, oracles)), map((s_1) => parse(s_1.price), tryFind((o_1) => {
            if (o_1.symbol === ("0x" + product.OracleQuote)) {
                return o_1.oracleType === "pyth";
            }
            else {
                return false;
            }
        }, oracles))];
        let pattern_matching_result, b, q;
        if (matchValue_1[0] != null) {
            if (matchValue_1[1] != null) {
                pattern_matching_result = 0;
                b = matchValue_1[0];
                q = matchValue_1[1];
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
        switch (pattern_matching_result) {
            case 0: {
                spot = op_Division(b, q);
                break;
            }
            case 1: {
                spot = fromParts(0, 0, 0, false, 0);
                break;
            }
        }
        let optionPrice;
        if (equals(spot, fromParts(0, 0, 0, false, 0))) {
            optionPrice = fromParts(0, 0, 0, false, 0);
        }
        else {
            const price = estimatedInjectiveHoldingYieldForEscrowVolatilityPrice(product, matchValue.fields[0], spot, strike, fromNumber(totalSeconds(ttm), true));
            const matchValue_2 = InjectiveProduct__GetKind(product);
            optionPrice = ((matchValue_2.tag === 1) ? op_Division(op_Multiply(price, spot), strike) : price);
        }
        const totalDeposited = op_Division(toDecimal(Option__GetCollateralAmount(value_83(escrowData.Product.Option))), multiplier);
        const totalTokenMinted = Escrow__GetTotalNumberOfPositionTokenMintedDecimal(escrowData.Escrow, escrowData.Product.Holding, value_83(escrowData.Product.Option));
        const amountMax = Escrow__GetBalanceDecimal_Z524259A4(escrowData.Escrow, tokens.Deposit.decimals);
        price_1 = (endsWith(escrowData.Escrow.TokenPosition.Denom, "position") ? op_Division(op_Multiply(op_Multiply(totalDeposited, optionPrice), amountMax), totalTokenMinted) : op_Multiply(op_Subtraction_1(op_Addition(totalDeposited, op_Division(Rfq__GetSelectedBid(value_83(escrowData.Product.Rfq)), multiplier)), op_Multiply(totalDeposited, optionPrice)), op_Division(amountMax, totalTokenMinted)));
    }
    const priceRounded = showSoldOut ? fromParts(0, 0, 0, false, 0) : floorDecimal(6, op_Division(price_1, nbOption));
    price_2 = (equals(priceRounded, fromParts(0, 0, 0, false, 0)) ? "\u003c 0.000001" : toText(interpolate("%P()", [priceRounded])));
    return createElement("div", {
        className: join(" ", [single$002Dproducts$002Emodule["position-row-current"], utils$002Emodule["margin-s-top"], "card"]),
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton(createElement(TextColumn, {
            text: InjectiveProduct_GetEscrowProductName(tokens.Spot.symbol, product),
            classes: ofArray([utils$002Emodule.center, utils$002Emodule["grid-column-1"]]),
        })), delay(() => {
            let qtyText;
            if (showSoldOut) {
                qtyText = "0";
            }
            else {
                const rounded = floorDecimal(2, nbOption);
                qtyText = (equals(rounded, fromParts(0, 0, 0, false, 0)) ? "\u003c 0.01" : toText(interpolate("%P()", [rounded])));
            }
            return append(singleton(createElement(TextColumn, {
                text: qtyText,
                classes: ofArray([utils$002Emodule.center, utils$002Emodule["grid-column-2"]]),
            })), delay(() => {
                const priceText = showSoldOut ? "-" : ((price_2 + " ") + tokens.Deposit.symbol);
                return append(singleton(createElement(TextColumn, {
                    text: priceText,
                    classes: ofArray([utils$002Emodule.center, utils$002Emodule["grid-column-3"]]),
                })), delay(() => {
                    let copyOfStruct;
                    return append(singleton(createElement(TextColumn, {
                        text: "$" + (copyOfStruct = toNumber(strike), copyOfStruct.toString()),
                        classes: ofArray([utils$002Emodule.center, utils$002Emodule["no-wrap"], utils$002Emodule["grid-column-4"], "text-color-normal"]),
                    })), delay(() => append(singleton(createElement(TextColumn, {
                        text: toString(maturity) + " days",
                        classes: ofArray([utils$002Emodule.center, utils$002Emodule["no-wrap"], utils$002Emodule["grid-column-5"], "text-color-normal"]),
                    })), delay(() => append(singleton(createElement(Column, {
                        children: singleton_1(createElement(TimeCountdown, {
                            seconds: ~(~totalSeconds(ttm)),
                        })),
                        classes: ofArray([utils$002Emodule.center, utils$002Emodule["no-wrap"], utils$002Emodule["grid-column-6"], "text-color-normal"]),
                    })), delay(() => singleton(createElement(Column, {
                        children: singleton_1(button(showSoldOut)),
                        classes: ofArray([utils$002Emodule.center, utils$002Emodule["grid-column-7"], "text-color-normal"]),
                    }))))))));
                }));
            }));
        })))))),
    });
}

export function BuyPopupButton(buyPopupButtonInputProps) {
    const isSoldOut = buyPopupButtonInputProps.isSoldOut;
    const setIsOpen = buyPopupButtonInputProps.setIsOpen;
    const setSelectedDataWithoutEscrow = buyPopupButtonInputProps.setSelectedDataWithoutEscrow;
    const setSelectedEscrowContract = buyPopupButtonInputProps.setSelectedEscrowContract;
    const setOracles = buyPopupButtonInputProps.setOracles;
    const setEscrows = buyPopupButtonInputProps.setEscrows;
    const oracles = buyPopupButtonInputProps.oracles;
    const data = buyPopupButtonInputProps.data;
    const patternInput = Recoil_useRecoilState_Z74A95619(tokens_11);
    return createElement("button", {
        className: join(" ", ["go-button"]),
        style: createObj(toList(delay(() => (isSoldOut ? singleton(["backgroundColor", "#808080"]) : empty())))),
        onClick: (_arg1) => {
            startImmediate(singleton_2.Delay(() => singleton_2.Bind(processInjectiveEscrowData([data], patternInput[0], false), (_arg1_1) => {
                const data_1 = _arg1_1;
                setEscrows(Result_Map((x) => toArray(x), data_1));
                setOracles(oracles);
                return singleton_2.Combine(isSoldOut ? (setSelectedDataWithoutEscrow((data_1.tag === 0) ? head_1(data_1.fields[0])[0].Product.Holding.Id : (void 0)), singleton_2.Zero()) : (setSelectedEscrowContract((data_1.tag === 0) ? head_1(data_1.fields[0])[0].Escrow.ContractAddress : (void 0)), singleton_2.Zero()), singleton_2.Delay(() => {
                    setIsOpen(true);
                    return singleton_2.Zero();
                }));
            })));
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => (isSoldOut ? singleton("Sold Out") : singleton(createElement("img", {
            src: manageLiquidity,
        }))))))),
    });
}

export function getOnChainEscrowData(config, environment, endpoints) {
    return singleton_2.Delay(() => singleton_2.TryWith(singleton_2.Delay(() => singleton_2.Bind(getWasmContractsListQuery(chainGrpcWasmApi(endpoints.grpc, environment), config.InjectiveEscrowCodeId), (_arg1) => singleton_2.Return(_arg1))), (_arg2) => singleton_2.Return([])));
}

export function OptionalityData(optionalityDataInputProps) {
    let value, children_1;
    const refreshDataToggle = optionalityDataInputProps.refreshDataToggle;
    const setIsOpen = optionalityDataInputProps.setIsOpen;
    const setUserInjectiveBalances = optionalityDataInputProps.setUserInjectiveBalances;
    const userInjectiveBalances = optionalityDataInputProps.userInjectiveBalances;
    const setSelectedDataWithoutEscrow = optionalityDataInputProps.setSelectedDataWithoutEscrow;
    const setSelectedEscrowContract = optionalityDataInputProps.setSelectedEscrowContract;
    const setOracles = optionalityDataInputProps.setOracles;
    const setEscrows = optionalityDataInputProps.setEscrows;
    const environment = Recoil_useRecoilValue_5D3EEEEA(injectiveEnvironment_1);
    const config = Recoil_useRecoilValue_5D3EEEEA(config_2);
    const patternInput = useFeliz_React__React_useState_Static_1505(new Deferred$1(0));
    const setPositions = patternInput[1];
    const positions = patternInput[0];
    const wallet = useWalletStore();
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const setRefreshDisabled = patternInput_1[1];
    const refreshDisabled = patternInput_1[0];
    const defaultEscrowFIlter = new EscrowFilters(void 0, void 0, void 0, void 0);
    const tokens = Recoil_useRecoilValue_5D3EEEEA(tokens_11);
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(defaultEscrowFIlter);
    const setEscrowFilter = patternInput_2[1];
    const escrowFilter = patternInput_2[0];
    const pageSize = useFeliz_React__React_useState_Static_1505(5)[0] | 0;
    const patternInput_4 = useFeliz_React__React_useState_Static_1505(1);
    const page = patternInput_4[0] | 0;
    const patternInput_5 = useFeliz_React__React_useState_Static_1505(new Deferred$1(0));
    const setPagedData = patternInput_5[1];
    const pagedData = patternInput_5[0];
    const patternInput_6 = useFeliz_React__React_useState_Static_1505(void 0);
    const setTotalPage = patternInput_6[1];
    const patternInput_7 = useFeliz_React__React_useState_Static_1505(new SortOrderType(2));
    const sortOrderType = patternInput_7[0];
    const setSortOrderType = patternInput_7[1];
    const patternInput_8 = useFeliz_React__React_useState_Static_1505(new SortOrder(0));
    const sortOrderQuantity = patternInput_8[0];
    const setSortOrderQuantity = patternInput_8[1];
    const patternInput_9 = useFeliz_React__React_useState_Static_1505(new SortOrder(0));
    const sortOrderStrike = patternInput_9[0];
    const setSortOrderStrike = patternInput_9[1];
    const patternInput_10 = useFeliz_React__React_useState_Static_1505(new SortOrder(0));
    const sortOrderExpiry = patternInput_10[0];
    const setSortOrderExpiry = patternInput_10[1];
    const onSortQuantityCallback = useReact_useCallback_93353E((sorder) => {
        setSortOrderType(new SortOrderType(0));
        setSortOrderQuantity(sorder);
    });
    const onSortStrikeCallback = useReact_useCallback_93353E((sorder_1) => {
        setSortOrderType(new SortOrderType(1));
        setSortOrderStrike(sorder_1);
    });
    const onSortExpiryCallback = useReact_useCallback_93353E((sorder_2) => {
        setSortOrderType(new SortOrderType(2));
        setSortOrderExpiry(sorder_2);
    });
    const changePageCallback = useReact_useCallback_93353E((newPage) => {
        patternInput_4[1](newPage);
    });
    useReact_useEffect_Z101E1A95(() => {
        startImmediate(singleton_2.Delay(() => {
            if (positions.tag === 0) {
                setPagedData(new Deferred$1(0));
                return singleton_2.Zero();
            }
            else if (positions.tag === 1) {
                setPagedData(new Deferred$1(1));
                return singleton_2.Zero();
            }
            else if (positions.tag === 3) {
                setPagedData(new Deferred$1(3, positions.fields[0]));
                return singleton_2.Zero();
            }
            else if (positions.fields[0].tag === 1) {
                setPagedData(new Deferred$1(2, new FSharpResult$2(1, positions.fields[0].fields[0])));
                return singleton_2.Zero();
            }
            else {
                return singleton_2.TryWith(singleton_2.Delay(() => {
                    let temp, sorted3, arr_3;
                    const filtered = positions.fields[0].fields[0][0].filter((tupledArg) => {
                        let matchValue, k, matchValue_1, day, copyOfStruct, matchValue_2, day_1, copyOfStruct_1;
                        const d = tupledArg[0];
                        if (((matchValue = escrowFilter.OptionKind, (matchValue == null) ? true : (k = matchValue, toString_1(InjectiveProduct__GetKind(d.Product.Product)) === toString_1(k))) ? (matchValue_1 = escrowFilter.DayBefore, (matchValue_1 == null) ? true : (day = matchValue_1, compare_1((copyOfStruct = utcNow(), addDays(copyOfStruct, toNumber_1(day))), value_83(d.Product.Option).OptionExpiry) <= 0)) : false) ? (matchValue_2 = escrowFilter.DayAfter, (matchValue_2 == null) ? true : (day_1 = matchValue_2, compare_1((copyOfStruct_1 = utcNow(), addDays(copyOfStruct_1, toNumber_1(day_1))), value_83(d.Product.Option).OptionExpiry) >= 0)) : false) {
                            const matchValue_3 = escrowFilter.SpotDenom;
                            if (matchValue_3 == null) {
                                return true;
                            }
                            else {
                                return contains(tupledArg[1].Spot, matchValue_3, {
                                    Equals: (x, y) => equals_1(x, y),
                                    GetHashCode: (x) => structuralHash(x),
                                });
                            }
                        }
                        else {
                            return false;
                        }
                    });
                    const sortByQuantity = (arr) => {
                        if (sortOrderQuantity.tag === 1) {
                            return sortByDescending((tupledArg_2) => {
                                const d_2 = tupledArg_2[0];
                                const t_2 = tupledArg_2[1];
                                const matchValue_5 = d_2.Product.Option;
                                if (matchValue_5 == null) {
                                    return Escrow__GetBalanceDecimal_Z524259A4(d_2.Escrow, t_2.Deposit.decimals);
                                }
                                else {
                                    return Escrow__GetNbOptionDecimal(d_2.Escrow, d_2.Product.Product, matchValue_5, t_2.Deposit.decimals);
                                }
                            }, arr, {
                                Compare: (x_2, y_2) => compare(x_2, y_2),
                            });
                        }
                        else {
                            return sortBy((tupledArg_1) => {
                                const d_1 = tupledArg_1[0];
                                const t_1 = tupledArg_1[1];
                                const matchValue_4 = d_1.Product.Option;
                                if (matchValue_4 == null) {
                                    return Escrow__GetBalanceDecimal_Z524259A4(d_1.Escrow, t_1.Deposit.decimals);
                                }
                                else {
                                    return Escrow__GetNbOptionDecimal(d_1.Escrow, d_1.Product.Product, matchValue_4, t_1.Deposit.decimals);
                                }
                            }, arr, {
                                Compare: (x_1, y_1) => compare(x_1, y_1),
                            });
                        }
                    };
                    const sortByStrike = (arr_1) => {
                        if (sortOrderStrike.tag === 1) {
                            return sortByDescending((tupledArg_4) => {
                                const d_4 = tupledArg_4[0];
                                const matchValue_7 = d_4.Product.Option;
                                if (matchValue_7 == null) {
                                    return InjectiveProduct__GetStrikeWithSpot_32C73145(d_4.Product.Product, fromParts(1, 0, 0, false, 0));
                                }
                                else {
                                    return matchValue_7.Strike;
                                }
                            }, arr_1, {
                                Compare: (x_4, y_4) => compare(x_4, y_4),
                            });
                        }
                        else {
                            return sortBy((tupledArg_3) => {
                                const d_3 = tupledArg_3[0];
                                const matchValue_6 = d_3.Product.Option;
                                if (matchValue_6 == null) {
                                    return InjectiveProduct__GetStrikeWithSpot_32C73145(d_3.Product.Product, fromParts(1, 0, 0, false, 0));
                                }
                                else {
                                    return matchValue_6.Strike;
                                }
                            }, arr_1, {
                                Compare: (x_3, y_3) => compare(x_3, y_3),
                            });
                        }
                    };
                    const sortByExpiry = (arr_2) => {
                        try {
                            return (sortOrderExpiry.tag === 1) ? sortByDescending((tupledArg_6) => value_83(tupledArg_6[0].Product.Option).OptionExpiry, arr_2, {
                                Compare: (x_6, y_6) => compare_1(x_6, y_6),
                            }) : sortBy((tupledArg_5) => value_83(tupledArg_5[0].Product.Option).OptionExpiry, arr_2, {
                                Compare: (x_5, y_5) => compare_1(x_5, y_5),
                            });
                        }
                        catch (matchValue_8) {
                            return arr_2;
                        }
                    };
                    setPagedData(new Deferred$1(2, new FSharpResult$2(0, [(temp = skip((page - 1) * pageSize, (sorted3 = sortByExpiry(sortByStrike(sortByQuantity(filtered))), (arr_3 = ((sortOrderType.tag === 1) ? sortByStrike : ((sortOrderType.tag === 2) ? sortByExpiry : sortByQuantity))(sorted3), (() => {
                        try {
                            return sortBy((tupledArg_7) => {
                                const d_7 = tupledArg_7[0];
                                const t_3 = tupledArg_7[1];
                                let nbOption;
                                const arg20_2 = t_3.Deposit.decimals | 0;
                                nbOption = Escrow__GetNbOptionDecimal(d_7.Escrow, d_7.Product.Product, value_83(d_7.Product.Option), arg20_2);
                                if (equals(nbOption, fromParts(0, 0, 0, false, 0)) ? true : (compare(op_Division(toDecimal(d_7.Escrow.MinQuantity), pow(fromParts(10, 0, 0, false, 0), t_3.Deposit.decimals)), nbOption) > 0)) {
                                    return 1;
                                }
                                else {
                                    return 0;
                                }
                            }, arr_3, {
                                Compare: (x_7, y_7) => comparePrimitives(x_7, y_7),
                            });
                        }
                        catch (matchValue_9) {
                            return arr_3;
                        }
                    })()))), (temp.length < pageSize) ? temp : take(pageSize, temp)), positions.fields[0].fields[0][1]])));
                    const len = filtered.length | 0;
                    setTotalPage((len <= pageSize) ? 1 : (((len % pageSize) === 0) ? (~(~(len / pageSize))) : ((~(~(len / pageSize))) + 1)));
                    return singleton_2.Zero();
                }), (_arg1_1) => {
                    return singleton_2.Zero();
                });
            }
        }));
    }, [pageSize, page, positions, escrowFilter, sortOrderQuantity, sortOrderStrike, sortOrderExpiry]);
    const getPositions = () => singleton_2.Delay(() => {
        setRefreshDisabled(true);
        setPositions(new Deferred$1(1));
        return singleton_2.Combine(singleton_2.TryWith(singleton_2.Delay(() => {
            let config_1;
            const endpoints = getNetworkEndpoints(getNetworkByInjectiveEnvironment(environment));
            const wasmApi = chainGrpcWasmApi(endpoints.grpc, environment);
            return singleton_2.Combine((config != null) ? (config_1 = config, singleton_2.Bind(getOnChainHoldingProductData(config_1, environment, endpoints, false), (_arg2_1) => {
                const oracles_1 = _arg2_1[4];
                return singleton_2.Bind(getOnChainEscrowData(config_1, environment, endpoints), (_arg3_1) => {
                    const escrowsFromChain = _arg3_1;
                    return singleton_2.Bind(Api.GetInjectiveEscrows(environment, []), (_arg4_1) => {
                        const escrowsFromDb = _arg4_1;
                        return singleton_2.Bind(getOnChainHoldingOptionRfqData(_arg2_1[0], _arg2_1[2], _arg2_1[3]), (_arg5) => singleton_2.Bind(processInjectiveHoldingProductDataDashboard(_arg5, _arg2_1[1]), (_arg6) => {
                            const allholdings = _arg6;
                            const getSelf = (data_1) => startAsPromise(singleton_2.Delay(() => singleton_2.Return(data_1)));
                            if ((escrowsFromChain.length - escrowsFromDb.length) > 5) {
                                return singleton_2.Bind(processInjectiveEscrowDataOptionality(allholdings, map_1((tupledArg_8) => tupledArg_8[0], escrowsFromDb)), (_arg7) => {
                                    let pr_1;
                                    const pr = map_1((d_8) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (getSelf(d_8).then(((_arg8) => (Promise.resolve(_arg8))))))), _arg7);
                                    pr_1 = (Promise.all(pr));
                                    pr_1.then(((data_3) => {
                                        const d_10 = toArray_1(choose((d_9) => {
                                            let matchValue_10;
                                            const matchValue_11 = [fetchInjectiveToken((matchValue_10 = InjectiveProduct__GetKind(d_9.Product.Product), (matchValue_10.tag === 1) ? d_9.Product.Product.QuoteDenom : d_9.Product.Product.BaseDenom), tokens), fetchInjectiveToken(d_9.Product.Product.BaseDenom, tokens), fetchInjectiveToken(d_9.Product.Product.BaseDenom, tokens), fetchInjectiveToken(d_9.Product.Product.QuoteDenom, tokens)];
                                            let pattern_matching_result, deposit, productPairLeft_1, productPairRight_1, spot;
                                            if (matchValue_11[0] != null) {
                                                if (matchValue_11[1] != null) {
                                                    if (matchValue_11[2] != null) {
                                                        if (matchValue_11[3] != null) {
                                                            pattern_matching_result = 0;
                                                            deposit = matchValue_11[0];
                                                            productPairLeft_1 = matchValue_11[2];
                                                            productPairRight_1 = matchValue_11[3];
                                                            spot = matchValue_11[1];
                                                        }
                                                        else {
                                                            pattern_matching_result = 1;
                                                        }
                                                    }
                                                    else {
                                                        pattern_matching_result = 1;
                                                    }
                                                }
                                                else {
                                                    pattern_matching_result = 1;
                                                }
                                            }
                                            else {
                                                pattern_matching_result = 1;
                                            }
                                            switch (pattern_matching_result) {
                                                case 0: {
                                                    return [d_9, new ProductDataTokens(deposit, spot, productPairLeft_1, productPairRight_1)];
                                                }
                                                case 1: {
                                                    return void 0;
                                                }
                                            }
                                        }, data_3));
                                        const len_1 = d_10.length | 0;
                                        const totalPage_2 = ((len_1 <= pageSize) ? 1 : (((len_1 % pageSize) === 0) ? (~(~(len_1 / pageSize))) : ((~(~(len_1 / pageSize))) + 1))) | 0;
                                        setPositions(new Deferred$1(2, new FSharpResult$2(0, [d_10, oracles_1])));
                                        setTotalPage(totalPage_2);
                                    }));
                                    return singleton_2.Zero();
                                });
                            }
                            else {
                                const escrowAddrFromDb = map_1((tupledArg_9) => tupledArg_9[0].ContractAddress, escrowsFromDb);
                                const escrowDataFromDb = map_1((tupledArg_10) => tupledArg_10[0], escrowsFromDb);
                                return singleton_2.Bind(getEscrowArrFromStringArr(wasmApi, escrowsFromChain.filter((addr) => (!contains(addr, escrowAddrFromDb, {
                                    Equals: (x_8, y_8) => (x_8 === y_8),
                                    GetHashCode: (x_8) => stringHash(x_8),
                                }))), "\"get_state_and_balance\""), (_arg9) => {
                                    const escrows_1 = choose_1((x_9) => x_9, _arg9);
                                    return singleton_2.Bind(processInjectiveEscrowDataOptionality(allholdings, concat([escrowDataFromDb, escrows_1])), (_arg10) => {
                                        let pr_3;
                                        const pr_2 = map_1((d_11) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (getSelf(d_11).then(((_arg11) => (Promise.resolve(_arg11))))))), _arg10);
                                        pr_3 = (Promise.all(pr_2));
                                        pr_3.then(((data_5) => {
                                            const d_13 = toArray_1(choose((d_12) => {
                                                let matchValue_12;
                                                const matchValue_13 = [fetchInjectiveToken((matchValue_12 = InjectiveProduct__GetKind(d_12.Product.Product), (matchValue_12.tag === 1) ? d_12.Product.Product.QuoteDenom : d_12.Product.Product.BaseDenom), tokens), fetchInjectiveToken(d_12.Product.Product.BaseDenom, tokens), fetchInjectiveToken(d_12.Product.Product.BaseDenom, tokens), fetchInjectiveToken(d_12.Product.Product.QuoteDenom, tokens)];
                                                let pattern_matching_result_1, deposit_1, productPairLeft_3, productPairRight_3, spot_1;
                                                if (matchValue_13[0] != null) {
                                                    if (matchValue_13[1] != null) {
                                                        if (matchValue_13[2] != null) {
                                                            if (matchValue_13[3] != null) {
                                                                pattern_matching_result_1 = 0;
                                                                deposit_1 = matchValue_13[0];
                                                                productPairLeft_3 = matchValue_13[2];
                                                                productPairRight_3 = matchValue_13[3];
                                                                spot_1 = matchValue_13[1];
                                                            }
                                                            else {
                                                                pattern_matching_result_1 = 1;
                                                            }
                                                        }
                                                        else {
                                                            pattern_matching_result_1 = 1;
                                                        }
                                                    }
                                                    else {
                                                        pattern_matching_result_1 = 1;
                                                    }
                                                }
                                                else {
                                                    pattern_matching_result_1 = 1;
                                                }
                                                switch (pattern_matching_result_1) {
                                                    case 0: {
                                                        return [d_12, new ProductDataTokens(deposit_1, spot_1, productPairLeft_3, productPairRight_3)];
                                                    }
                                                    case 1: {
                                                        return void 0;
                                                    }
                                                }
                                            }, data_5));
                                            const len_2 = d_13.length | 0;
                                            const totalPage_3 = ((len_2 <= pageSize) ? 1 : (((len_2 % pageSize) === 0) ? (~(~(len_2 / pageSize))) : ((~(~(len_2 / pageSize))) + 1))) | 0;
                                            setPositions(new Deferred$1(2, new FSharpResult$2(0, [d_13, oracles_1])));
                                            setTotalPage(totalPage_3);
                                        }));
                                        return singleton_2.Bind(Api.AddOrUpdateInjectiveEscrows(environment, escrows_1), () => singleton_2.Return());
                                    });
                                });
                            }
                        }));
                    });
                });
            })) : (setPositions(new Deferred$1(0)), singleton_2.Zero()), singleton_2.Delay(() => singleton_2.Bind(sleep(10000 * 2), () => {
                setRefreshDisabled(false);
                return singleton_2.Zero();
            })));
        }), (_arg14) => singleton_2.Return()), singleton_2.Delay(() => {
            setRefreshDisabled(false);
            return singleton_2.Zero();
        }));
    });
    useReact_useEffect_Z101E1A95(() => {
        startImmediate(getPositions());
    }, [tokens, environment, refreshDataToggle]);
    const getPositionsCallback = useReact_useCallback_93353E(() => {
        startImmediate(getPositions());
    }, [tokens, environment]);
    const noData = createElement("div", createObj(ofArray([(value = utils$002Emodule["h-center"], ["className", value]), ["children", Interop_reactApi.Children.toArray([createElement(NoData, {
        title: "No Open Option Position",
        message: "Try single products here!",
        buttonText: "Go to Single Products",
        onClick: () => {
            RouterModule_nav(ofArray(["products", ""]), 1, 1);
        },
    })])]])));
    const productKindStringList = ["", "Bullish", "Bearish"];
    let dateRangeList;
    let pattern_matching_result_2;
    if (positions.tag === 2) {
        if (positions.fields[0].tag === 0) {
            pattern_matching_result_2 = 0;
        }
        else {
            pattern_matching_result_2 = 1;
        }
    }
    else {
        pattern_matching_result_2 = 1;
    }
    switch (pattern_matching_result_2) {
        case 0: {
            try {
                dateRangeList = concat([[["None", [0, 1000]]], [["within 7 Days", [0, 7]], ["8 - 14 Days", [8, 14]], ["15 - 30 Days", [15, 30]], ["31 - 90 Days", [31, 90]], ["91 or more", [91, 1000]]]]);
            }
            catch (matchValue_14) {
                dateRangeList = [];
            }
            break;
        }
        case 1: {
            dateRangeList = [];
            break;
        }
    }
    const patternInput_11 = useFeliz_React__React_useState_Static_1505([]);
    const setSpotDenomList = patternInput_11[1];
    useReact_useEffect_Z101E1A95(() => {
        startImmediate(singleton_2.Delay(() => singleton_2.TryWith(singleton_2.Delay(() => {
            let pattern_matching_result_3, allPositions_1;
            if (positions.tag === 2) {
                if (positions.fields[0].tag === 0) {
                    pattern_matching_result_3 = 0;
                    allPositions_1 = positions.fields[0].fields[0];
                }
                else {
                    pattern_matching_result_3 = 1;
                }
            }
            else {
                pattern_matching_result_3 = 1;
            }
            switch (pattern_matching_result_3) {
                case 0: {
                    return singleton_2.TryWith(singleton_2.Delay(() => {
                        const arr_4 = sortBy((s_1) => {
                            const matchValue_15 = s_1.symbol.toLocaleLowerCase();
                            switch (matchValue_15) {
                                case "inj": {
                                    return 0;
                                }
                                case "weth": {
                                    return 1;
                                }
                                case "atom": {
                                    return 2;
                                }
                                default: {
                                    return 3;
                                }
                            }
                        }, Array_distinct(map_1((tupledArg_12) => tupledArg_12[1].Spot, allPositions_1[0]), {
                            Equals: (x_10, y_9) => equals_1(x_10, y_9),
                            GetHashCode: (x_10) => structuralHash(x_10),
                        }), {
                            Compare: (x_11, y_10) => comparePrimitives(x_11, y_10),
                        });
                        setSpotDenomList(concat([[createToken("", 0, 0, "", "", "All")], arr_4]));
                        return singleton_2.Zero();
                    }), (_arg15) => {
                        setSpotDenomList([]);
                        return singleton_2.Zero();
                    });
                }
                case 1: {
                    setSpotDenomList([]);
                    return singleton_2.Zero();
                }
            }
        }), (_arg16) => {
            setSpotDenomList([]);
            return singleton_2.Zero();
        })));
    }, [positions]);
    return createElement("div", {
        children: Interop_reactApi.Children.toArray([createElement(Col, {
            classes: empty_1(),
            children: toList(delay(() => append(singleton(createElement(Col, {
                classes: singleton_1(utils$002Emodule["gap-m"]),
                children: singleton_1(createElement("div", {
                    className: join(" ", ["header-5", utils$002Emodule["margin-l-top"], utils$002Emodule["margin-l-bottom"]]),
                    children: "Vanilla Options on-chain",
                })),
            })), delay(() => {
                const height = 95;
                return append(singleton(createElement("div", {
                    style: {
                        display: "flex",
                    },
                    children: Interop_reactApi.Children.toArray([createElement("div", {
                        className: join(" ", ["textbox-explanation"]),
                        children: "Options give you the right to buy or sell an asset at a predetermined price on a future date, allowing you to profit from market movements with limited risk.",
                    }), createElement("div", {
                        style: {
                            height: height,
                            width: 100,
                            flexGrow: 1,
                            backgroundColor: "#00000",
                        },
                        children: "",
                    }), createElement("div", {
                        className: join(" ", ["textbox-explanation"]),
                        children: "Vanilla Options on Exotic Markets are fully collateralized on-chain, ensuring fair settlement with Pyth oracle, eliminating counterparty risk.",
                    }), createElement("div", {
                        style: {
                            height: height,
                            width: 100,
                            flexGrow: 1,
                            backgroundColor: "#00000",
                        },
                        children: "",
                    }), createElement("div", {
                        className: join(" ", ["textbox-explanation"]),
                        children: "Bet on a token\u0027s value rise with Call Options or on its fall with Put Options.",
                    }), createElement("div", {
                        style: {
                            height: height,
                            width: 100,
                            flexGrow: 1,
                            backgroundColor: "#00000",
                        },
                        children: "",
                    }), createElement("div", {
                        className: join(" ", ["textbox-explanation"]),
                        children: "Pick the maturity that matches your view, and the strike price determines the activation price of the options.",
                    })]),
                })), delay(() => {
                    let children_3;
                    return append(singleton(createElement(Col, {
                        classes: ofArray([utils$002Emodule["margin-m-top"], utils$002Emodule["margin-l-bottom"]]),
                        children: singleton_1((children_3 = ofArray([createElement("a", {
                            children: "Read our docs to learn about options ",
                        }), createElement("a", {
                            href: "https://docs.exotic.markets/exotic-markets-v2/what-are-options",
                            target: "_blank",
                            children: "here",
                        })]), createElement("div", {
                            children: Interop_reactApi.Children.toArray(Array.from(children_3)),
                        }))),
                    })), delay(() => singleton(createElement(Col, {
                        classes: singleton_1(utils$002Emodule["margin-m-top"]),
                        children: singleton_1(createElement("div", {
                            style: {
                                width: 200 + "px",
                            },
                            disabled: refreshDisabled,
                            className: join(" ", [utils$002Emodule["cursor-pointer"], utils$002Emodule.center, "button-dropdown", "button-refresh-small"]),
                            onClick: (_arg9_1) => {
                                getPositionsCallback();
                            },
                            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => (refreshDisabled ? singleton(createElement(Row, {
                                classes: singleton_1(utils$002Emodule.center),
                                children: singleton_1(createElement(ButtonProgressLoader, {
                                    classes: empty_1(),
                                })),
                            })) : append(singleton("Refresh"), delay(() => singleton(react.createElement(Refresh, {}))))))))),
                        })),
                    }))));
                }));
            })))),
        }), createElement("div", {
            className: join(" ", [utils$002Emodule["margin-m-top"]]),
            style: {
                display: "flex",
                justifyItems: "auto",
            },
            children: Interop_reactApi.Children.toArray([createElement(EscrowOptionKindSelectBox, {
                label: "Market View",
                options: productKindStringList,
                filterValue: escrowFilter,
                dispatch: setEscrowFilter,
            }), createElement(EscrowExpirySelectBox, {
                label: "Expiry",
                options: dateRangeList,
                filterValue: escrowFilter,
                dispatch: setEscrowFilter,
            }), createElement(EscrowCurrencySelectBox, {
                label: "Currency",
                options: patternInput_11[0],
                filterValue: escrowFilter,
                dispatch: setEscrowFilter,
            }), createElement("div", {
                className: join(" ", [utils$002Emodule["cursor-pointer"], utils$002Emodule.center, "button-dropdown", "button-refresh-small"]),
                style: {
                    minWidth: 100,
                    maxWidth: 120,
                },
                children: Interop_reactApi.Children.toArray(["Reset"]),
                onClick: (_arg11_1) => {
                    setEscrowFilter(defaultEscrowFIlter);
                    setSortOrderType(new SortOrderType(2));
                    setSortOrderQuantity(new SortOrder(0));
                    setSortOrderStrike(new SortOrder(0));
                    setSortOrderExpiry(new SortOrder(0));
                },
            })]),
        }), (children_1 = toList(delay(() => {
            let tupledArg_11, data_6;
            return (pagedData.tag === 0) ? singleton(noData) : ((pagedData.tag === 1) ? singleton(createElement(Row, {
                classes: ofArray([utils$002Emodule.center, utils$002Emodule["margin-m-top"]]),
                children: singleton_1(MuiHelpers_createElement(CircularProgress, [])),
            })) : ((pagedData.tag === 3) ? singleton(createElement(Row, {
                classes: singleton_1(utils$002Emodule.center),
                children: singleton_1(createElement(ErrorMessage, {
                    message: toString_1(pagedData.fields[0]),
                })),
            })) : ((pagedData.fields[0].tag === 1) ? singleton(createElement(Row, {
                classes: singleton_1(utils$002Emodule.center),
                children: singleton_1(createElement(ErrorMessage, {
                    message: pagedData.fields[0].fields[0],
                })),
            })) : singleton((tupledArg_11 = pagedData.fields[0].fields[0], (data_6 = [tupledArg_11[0], tupledArg_11[1]], react.createElement(react.Fragment, {}, ...toList(delay(() => append(singleton(createElement(OptionalityHeader, {
                buttonHeader: "Buy",
                quantitySortOrder: sortOrderQuantity,
                sortQuantity: onSortQuantityCallback,
                priceSortOrder: sortOrderStrike,
                sortPrice: onSortStrikeCallback,
                expirySortOrder: sortOrderExpiry,
                sortExpiry: onSortExpiryCallback,
            })), delay(() => map_2((product) => createElement(OptionalityRow, {
                data: [product],
                oracles: data_6[1],
                button: (isSoldOut) => createElement(BuyPopupButton, {
                    data: product[0],
                    oracles: data_6[1],
                    setEscrows: setEscrows,
                    setOracles: setOracles,
                    setSelectedEscrowContract: setSelectedEscrowContract,
                    setSelectedDataWithoutEscrow: setSelectedDataWithoutEscrow,
                    setIsOpen: setIsOpen,
                    isSoldOut: isSoldOut,
                }),
            }), data_6[0]))))))))))));
        })), createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(children_1)),
        })), createElement(PagingRow, {
            totalPages: patternInput_6[0],
            pageNumber: page,
            changePageCallback: changePageCallback,
        })]),
    });
}

export function Optionality() {
    const chain = Recoil_useRecoilValue_5D3EEEEA(blockchain);
    const walletCtx = useWallet();
    const connection = useConnection().connection;
    const patternInput = useFeliz_React__React_useState_Static_1505(new FSharpResult$2(0, []));
    const escrows = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505([]);
    const oracles = patternInput_1[0];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(void 0);
    const setSelectedEscrowContract = patternInput_2[1];
    const selectedEscrowContract = patternInput_2[0];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(void 0);
    const setSelectedDataWithoutEscrow = patternInput_3[1];
    const selectedDataWithoutEscrow = patternInput_3[0];
    const setModalOpen = useFeliz_React__React_useState_Static_1505(true)[1];
    const injectiveWallet = useWalletStore();
    const config = Recoil_useRecoilValue_5D3EEEEA(config_2);
    const injectiveEnvironment = Recoil_useRecoilValue_5D3EEEEA(injectiveEnvironment_1);
    const patternInput_5 = useFeliz_React__React_useState_Static_1505([]);
    const setUserInjectiveBalances = patternInput_5[1];
    const tokens = Recoil_useRecoilValue_5D3EEEEA(tokens_11);
    const generalMessageShown = Recoil_useRecoilValue_5D3EEEEA(generalMessageShown_1);
    const warningMessageShown = Recoil_useRecoilValue_5D3EEEEA(warningMessageShown_1);
    const patternInput_6 = useFeliz_React__React_useState_Static_1505(true);
    const refreshDataToggle = patternInput_6[0];
    const patternInput_7 = useFeliz_React__React_useState_Static_1505([]);
    const uiFormat = patternInput_7[0];
    const connectWalletMessage = createElement("div", {
        className: join(" ", ["connect-wallet-modal-2"]),
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => ((chain.tag === 1) ? singleton(createElement(ConnectInjectiveWalletMessage, injectiveWallet)) : singleton(createElement(ConnectWalletMessage, {
            connection: connection,
            walletCtx: walletCtx,
        }))))))),
    });
    useReact_useEffect_Z101E1A95(() => {
        startImmediate(singleton_2.Delay(() => {
            const matchValue = [injectiveWallet.walletStrategy, config];
            let pattern_matching_result;
            if (matchValue[0] != null) {
                if (matchValue[1] != null) {
                    pattern_matching_result = 0;
                }
                else {
                    pattern_matching_result = 1;
                }
            }
            else {
                pattern_matching_result = 1;
            }
            switch (pattern_matching_result) {
                case 0: {
                    if (injectiveWallet.injectiveAddress === "") {
                        return singleton_2.Zero();
                    }
                    else {
                        return singleton_2.Bind(getAddressBalancesWithPagination(chainGrpcBankApi(getNetworkEndpoints(getNetworkByInjectiveEnvironment(injectiveEnvironment)).grpc, injectiveEnvironment), injectiveWallet.injectiveAddress), (_arg1) => {
                            setUserInjectiveBalances(_arg1);
                            return singleton_2.Zero();
                        });
                    }
                }
                case 1: {
                    return singleton_2.Zero();
                }
            }
        }));
    }, [injectiveWallet, tokens, injectiveEnvironment, refreshDataToggle]);
    useReact_useEffect_Z101E1A95(() => {
        startImmediate(singleton_2.Delay(() => singleton_2.TryWith(singleton_2.Delay(() => singleton_2.Bind(Api.GetInjectiveUiFormat(), (_arg2) => {
            patternInput_7[1](_arg2);
            return singleton_2.Zero();
        })), (_arg3) => {
            return singleton_2.Zero();
        })));
    }, [tokens, injectiveEnvironment]);
    if (chain.tag === 1) {
        return createElement("div", {
            children: Interop_reactApi.Children.toArray([createElement("div", {
                children: Interop_reactApi.Children.toArray([createElement(Content, {
                    x: createElement(OptionalityData, {
                        setEscrows: patternInput[1],
                        setOracles: patternInput_1[1],
                        setSelectedEscrowContract: setSelectedEscrowContract,
                        setSelectedDataWithoutEscrow: setSelectedDataWithoutEscrow,
                        userInjectiveBalances: patternInput_5[0],
                        setUserInjectiveBalances: setUserInjectiveBalances,
                        setIsOpen: setModalOpen,
                        refreshDataToggle: refreshDataToggle,
                    }),
                    classes: empty_1(),
                }), createElement("div", {
                    children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                        let copyOfStruct;
                        if (escrows.tag === 1) {
                            return singleton(createElement(Row, {
                                classes: ofArray([utils$002Emodule.center, utils$002Emodule["padding-xl-top"]]),
                                children: singleton_1(createElement(ErrorText, {
                                    text: (copyOfStruct = escrows.fields[0], toString_1(copyOfStruct)),
                                    classes: empty_1(),
                                })),
                            }));
                        }
                        else if (selectedEscrowContract == null) {
                            return singleton(null);
                        }
                        else {
                            const address = selectedEscrowContract;
                            const escrows_1 = escrows.fields[0].filter((tupledArg) => (tupledArg[0].Escrow.ContractAddress === address));
                            return singleton(createElement(OptionalityDetail, {
                                escrowData: escrows_1,
                                oracles: oracles,
                                escrowContract: address,
                                onClose: () => {
                                    setSelectedEscrowContract(void 0);
                                    setModalOpen(false);
                                },
                                hideProductSelection: true,
                                refreshDataToggle: refreshDataToggle,
                                setRefreshDataToggle: patternInput_6[1],
                                uiFormat: uiFormat,
                            }));
                        }
                    })))),
                }), createElement("div", {
                    children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                        if (escrows.tag === 1) {
                            return singleton(createElement(Row, {
                                classes: ofArray([utils$002Emodule.center, utils$002Emodule["padding-xl-top"]]),
                                children: singleton_1(createElement(ErrorText, {
                                    text: "Error showing details.",
                                    classes: empty_1(),
                                })),
                            }));
                        }
                        else if (selectedDataWithoutEscrow == null) {
                            return singleton(null);
                        }
                        else {
                            const holdingId = selectedDataWithoutEscrow;
                            const d = map_1((tupledArg_2) => [tupledArg_2[0].Product, tupledArg_2[1]], escrows.fields[0].filter((tupledArg_1) => (tupledArg_1[0].Product.Holding.Id === holdingId)));
                            return singleton(createElement(OptionalityDetailWithoutEscrow, {
                                holdingProduct: d,
                                holdingId: holdingId,
                                oracles: oracles,
                                quantityBuy: "100",
                                onClose: () => {
                                    setSelectedDataWithoutEscrow(void 0);
                                    setModalOpen(false);
                                },
                                uiFormat: uiFormat,
                            }));
                        }
                    })))),
                })]),
            })]),
        });
    }
    else {
        return createElement("div", {
            className: join(" ", [utils$002Emodule["text-center"], utils$002Emodule["margin-xl-top"]]),
            children: Interop_reactApi.Children.toArray([createElement("div", {
                children: Interop_reactApi.Children.toArray([createElement("img", {
                    className: join(" ", []),
                    src: VaultComingSoon,
                    alt: "Coming soon",
                })]),
            })]),
        });
    }
}

